import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import ExpandableText from '@/components/ExpandableText';
import { TransText } from '@/i18n/trans/text';
import Changeability from '@/components/purchase/card/Changeability';
import Refundability from '@/components/purchase/card/Refundability';
import type { MultiRideAccountDTO, TravelPassAccountDTO } from '@/types/dto';
import type { DecoratedTravelPassDetails } from '@/types/manage';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import TravelPassAccountValidity from '@/components/manage/travel-passes/TravelPassAccountValidity';
import MultiRideAccountValidity from '@/components/manage/travel-passes/MultiRideAccountValidity';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';
import TicketCuticle from '@/components/manage/TicketCuticle';
import { cn } from '@/utils/cn';
import Divider from '@/components/primitives/Divider';

interface TravelPassDetailsProps {
  data: DecoratedTravelPassDetails<MultiRideAccountDTO | TravelPassAccountDTO>;
}

const TravelPassDetails: FC<TravelPassDetailsProps> = ({ data }) => {
  const currency = useSelector(currencySelector);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const zone =
    data.travelAccount?.regionalConstraint?.regionalValidities[0].zone;
  const origin = zone?.entryStation?.name;
  const destination = zone?.terminalStation?.name;
  const productDescriptiveText = data.productDescriptiveTexts?.[0];
  const passengerCard = data.bookingPassengers?.find(
    (y) => y.id === data.admissionPassengerIds?.[0]
  );

  const renderPassengerInfo = () => (
    <div className="flex flex-col">
      <Typography variant="body2-bold">
        {data.travelAccount?.holder?.firstName}
        &nbsp;
        {data.travelAccount?.holder?.lastName}
      </Typography>
      {!!passengerCard?.cards?.length && (
        <Typography variant="body2">
          {`${passengerCard.cards[0].name}`}: &nbsp;
          {passengerCard.cards[0].number}
        </Typography>
      )}
    </div>
  );

  const renderPrice = () => (
    <div data-testid="travel-pass-price">
      <Typography variant="body1-bold" className="text-primary">
        {data.price?.amount}
        {currency.symbol}
      </Typography>
    </div>
  );

  const renderValidity = () =>
    data.travelAccount?.objectType === 'TravelPassAccount' ? (
      <TravelPassAccountValidity travelAccount={data.travelAccount} />
    ) : (
      <MultiRideAccountValidity
        travelAccount={data.travelAccount as MultiRideAccountDTO}
      />
    );

  const renderRouteInfo = () => (
    <div className="bg-primary-pale text-primary">
      <div className="flex flex-row flex-wrap items-center px-4 py-3">
        <Typography variant="subtitle" className="min-w-fit">
          {origin}
        </Typography>
        <div className="mx-1.5 shrink-0">—</div>
        <Typography variant="subtitle" className="min-w-fit">
          {destination}
        </Typography>
      </div>
    </div>
  );

  const renderTravelPassInfo = () => (
    <>
      <div className="flex flex-row items-center gap-1">
        <Typography variant="body2" className="text-neutral">
          <TransText i18nKey="travelPass" />:
        </Typography>
        <Typography variant="body2">{data.travelAccount?.number}</Typography>
      </div>
      {!isLaptopOrBigger && (
        <div className="mt-2 flex flex-row items-center gap-3">
          <Changeability condition={data.exchangeable === 'YES'} />
          <Refundability condition={data.refundable === 'YES'} />
        </div>
      )}
    </>
  );

  return (
    <div
      className={cn(
        'flex rounded-lg bg-background bg-white text-dark',
        isLaptopOrBigger ? 'flex-row' : 'flex-col'
      )}
    >
      <div
        className={cn(
          'flex flex-col',
          isLaptopOrBigger ? 'w-2/3 px-4 py-3' : 'w-full p-3'
        )}
      >
        <div className="flex h-full flex-col">
          <Typography variant="subtitle" className="mb-3" asChild>
            <h2>
              {productDescriptiveText?.shortDescription ??
                productDescriptiveText?.description}
            </h2>
          </Typography>
          <ExpandableText text={productDescriptiveText?.description} />
        </div>

        <div
          className={cn(
            isLaptopOrBigger
              ? 'mt-auto flex flex-row items-center justify-between'
              : 'mt-4 flex flex-col'
          )}
        >
          {renderTravelPassInfo()}
          {isLaptopOrBigger && (
            <div className="flex flex-row items-center gap-3">
              <Changeability condition={data.exchangeable === 'YES'} />
              <Refundability condition={data.refundable === 'YES'} />
            </div>
          )}
        </div>
      </div>

      {isLaptopOrBigger ? (
        <>
          <TicketCuticle isVertical={true} />
          <div className="flex w-2/5 flex-col">
            {renderRouteInfo()}
            <div className="flex-grow px-4 py-3">{renderValidity()}</div>
            <div className="flex flex-row items-center justify-between px-4 pb-3">
              {renderPassengerInfo()}
              {renderPrice()}
            </div>
          </div>
        </>
      ) : (
        <>
          <Divider variant="medium" />
          <div className="w-full">
            {renderRouteInfo()}
            <div className="px-3 py-3">{renderValidity()}</div>
            <TicketCuticle isVertical={false} />
            <div className="flex flex-row items-center justify-between px-3 py-3">
              {renderPassengerInfo()}
              {renderPrice()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TravelPassDetails;
