import { type FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import ConfirmModal from '@/components/ConfirmModal';

interface ExpiryWarningDialogProps {
  open: boolean;
  timeTillExpiry: string;
  setOpen: (value: boolean) => void;
  onCancel: () => void;
}

const ExpiryWarningDialog: FC<ExpiryWarningDialogProps> = ({
  open,
  timeTillExpiry,
  setOpen,
  onCancel,
}) => {
  const [minutes, seconds] = timeTillExpiry.split(':');

  const parsedMinutes = parseInt(minutes);
  const parsedSeconds = parseInt(seconds);

  return (
    <ConfirmModal
      open={open}
      setOpen={setOpen}
      onCancel={onCancel}
      title={
        <Typography variant="subtitle">
          <TransText i18nKey="sessionAboutToExpire" />
        </Typography>
      }
      description={
        <>
          <Typography variant="paragraph" className="text-dark">
            <TransText i18nKey="bookingSessionExpiresIn" />:
          </Typography>
          <div className="bg-yellow flex w-1/4 items-center gap-1 text-dark">
            <Typography
              variant="paragraph"
              className="flex items-center gap-0.5"
            >
              <span className="inline-block text-center">{parsedMinutes}</span>
              <span>
                <TransText
                  i18nKey="minutesCount"
                  values={{ count: parsedMinutes }}
                />
              </span>
            </Typography>
            <Typography
              variant="paragraph"
              className="flex items-center gap-0.5"
            >
              <span className="inline-block text-center">{parsedSeconds}</span>
              <span>
                <TransText
                  i18nKey="secondsCount"
                  values={{ count: parsedSeconds }}
                />
              </span>
            </Typography>
          </div>
        </>
      }
      cancelLabel="dismissWarning"
    />
  );
};

export default ExpiryWarningDialog;
