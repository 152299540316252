import type { FC } from 'react';
import { BRAND_OPTIONS } from '@/utils/consts';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import type { BrandOption } from '@/types/color';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/primitives/Dialog';
import { Button } from '@/components/primitives/Button';
import { useState } from 'react';
import { useDispatch } from '@/store/utils';
import { getBrandConfiguration } from '@/features/configuration/configurationActions';

const MobileBrandSelect: FC = () => {
  const [selectedBrand, setSelectedBrand] = useState<BrandOption>(
    BRAND_OPTIONS[0]
  );
  const dispatch = useDispatch();

  const handleBrandChange = (brand: BrandOption) => {
    setSelectedBrand(brand);
    dispatch(getBrandConfiguration(brand.value));
  };

  return (
    <Dialog>
      <DialogTrigger
        className="flex flex-row items-center gap-2 px-0"
        data-testid="brand-select-trigger-mobile"
      >
        <Icons.powerSocket />
        <Typography variant="button">{selectedBrand.label}</Typography>
      </DialogTrigger>
      <DialogContent className="gap-0 p-0">
        <DialogHeader className="text-lef border-none p-4">
          <DialogTitle>Select Brand</DialogTitle>
          <DialogDescription hidden>Select Brand</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col">
          {BRAND_OPTIONS.map((option) => (
            <Button
              key={option.value}
              variant="ghost"
              className="flex h-14 w-full items-center px-4"
              onClick={() => handleBrandChange(option)}
              data-testid={`brand-select-choose-mobile-${option.value}`}
            >
              <Typography variant="body1">{option.label}</Typography>
              {option.value === selectedBrand.value && (
                <Icons.checkCircle
                  height={16}
                  width={16}
                  className="text-success-text"
                />
              )}
            </Button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MobileBrandSelect;
