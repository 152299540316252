import {
  type Dispatch,
  type FC,
  type SetStateAction,
  useCallback,
} from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { CardContent, CardPrimitiveRoot } from '@/components/primitives/Card';
import { Icons } from '@/components/icons';
import { Button } from '@/components/primitives/Button';
import Divider from '@/components/primitives/Divider';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useTranslation } from 'react-i18next';
import i18nInstance, { NAMESPACE } from '@/i18n';
import { useForm } from 'react-hook-form';
import {
  createSignupMethodSchema,
  type SignupMethodValues,
} from '@/utils/zod/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/primitives/Form';
import { InputField } from '@/components/form/InputField';

interface SignupMethodProps {
  setEmail: Dispatch<SetStateAction<string>>;
}

const SignupMethod: FC<SignupMethodProps> = ({ setEmail }) => {
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const { t } = useTranslation(NAMESPACE, { i18n: i18nInstance });
  const form = useForm<SignupMethodValues>({
    resolver: zodResolver(createSignupMethodSchema(t)),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = useCallback(
    (values: SignupMethodValues) => {
      setEmail(values.email);
    },
    [setEmail]
  );

  return (
    <div className="mx-5 mt-10 laptop:mx-auto laptop:max-w-[410px]">
      <Typography
        variant={isLaptopOrBigger ? 'title' : 'mobile-title'}
        className="text-center laptop:text-left"
        asChild
      >
        <h1>
          <TransText i18nKey="createAnAccount" />
        </h1>
      </Typography>
      <CardPrimitiveRoot className="mt-8 rounded-xl">
        <CardContent className="flex flex-col gap-6">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-6"
            >
              <InputField
                name="email"
                control={form.control}
                label={<TransText i18nKey="email" />}
                placeholder={t('text.enterYourEmailAddress')}
                required
              />
              <Button
                data-testid="signup-method-continue-with-email-button"
                className="w-full"
              >
                <Typography variant="button">
                  <TransText i18nKey="continueWithEmail" />
                </Typography>
              </Button>
            </form>
          </Form>

          <div className="flex w-full items-center gap-4">
            <Divider grow />
            <Typography variant="body1">
              <TransText i18nKey="orContinueWith" />
            </Typography>
            <Divider grow />
          </div>
          <div className="flex items-center justify-center gap-4">
            <Button
              variant="icon"
              data-testid="facebook-login-button"
              className="h-fit p-2"
            >
              <Icons.facebook />
            </Button>
            <Button
              variant="icon"
              data-testid="google-login-button"
              className="h-fit p-2"
            >
              <Icons.google />
            </Button>
            <Button
              variant="icon"
              data-testid="apple-login-button"
              className="h-fit p-2"
            >
              <Icons.apple />
            </Button>
          </div>
        </CardContent>
      </CardPrimitiveRoot>
    </div>
  );
};

export default SignupMethod;
