import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from '@/components/primitives/Sheet';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import type { FC, ReactNode } from 'react';
import { useCallback } from 'react';
import { useSelector } from '@/store/utils';
import { searchFormValuesSelector } from '@/features/purchase/purchaseSelectors';
import { TransText } from '@/i18n/trans/text';
import { useSearchWidgetContext } from '@/hooks/useSearchWidgetContext';
import { formatDateLLL } from '@/utils/date-time';

type MobileSearchWidgetProps = {
  children: ReactNode;
};

export const MobileSearchWidget: FC<MobileSearchWidgetProps> = ({
  children,
}) => {
  const currentSearchFormValues = useSelector(searchFormValuesSelector);
  const { isSearchOverlayOpened, setIsSearchOverlayOpened } =
    useSearchWidgetContext();

  const getDates = useCallback(() => {
    const { departureDate, returnDate } = currentSearchFormValues || {};
    const dates: JSX.Element[] = [];

    if (departureDate) {
      const formattedDepartureDate = formatDateLLL(departureDate);
      dates.push(
        <>
          <TransText i18nKey="outbound" />, {formattedDepartureDate}
        </>
      );
    }

    if (returnDate) {
      const formattedReturnDate = formatDateLLL(returnDate);
      dates.push(
        <>
          <TransText i18nKey="inbound" />, {formattedReturnDate}
        </>
      );
    }

    return dates.map((date, index) => (
      <span key={index}>
        {index > 0 && ' - '}
        {date}
      </span>
    ));
  }, [currentSearchFormValues]);

  return (
    <Sheet open={isSearchOverlayOpened} onOpenChange={setIsSearchOverlayOpened}>
      <SheetTrigger className="mx-auto flex w-full items-stretch px-5 py-4 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-tint focus-visible:ring-offset-2">
        <Icons.search className="flex-shrink-0" />
        <div className="ml-3 flex w-full flex-col items-start overflow-hidden">
          {currentSearchFormValues?.origin &&
          currentSearchFormValues?.destination ? (
            <>
              <div className="flex items-center gap-2 overflow-hidden text-start">
                <Typography variant="subtitle" className="truncate">
                  {currentSearchFormValues.origin.cityName}
                </Typography>
                <Icons.return className="flex-shrink-0" />
                <Typography variant="subtitle" className="truncate">
                  {currentSearchFormValues.destination.cityName}
                </Typography>
              </div>
              <div className="flex w-full flex-row items-center gap-2 overflow-hidden">
                <Typography variant="body1" className="truncate">
                  {getDates()}
                </Typography>
                <Icons.dot className="flex-shrink-0" />
                <Typography variant="body1" className="truncate">
                  <TransText
                    i18nKey="passengersCount"
                    count={currentSearchFormValues.passengers.length}
                  />
                </Typography>
              </div>
            </>
          ) : (
            <Typography variant="body1" className="truncate">
              <TransText i18nKey="clickHereToSearch" />
            </Typography>
          )}
        </div>
      </SheetTrigger>
      <SheetContent side="bottom" className="rounded-t-2xl p-0">
        <SheetHeader className="flex w-full flex-row items-center justify-between p-4 shadow-md">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-4">
              <Icons.search />
              <Typography variant="subtitle">
                <TransText i18nKey="editSearch" />
              </Typography>
            </div>
            <SheetClose>
              <Icons.xSmall className="h-6 w-6" />
              <span className="sr-only">
                <TransText i18nKey="close" />
              </span>
            </SheetClose>
          </div>
        </SheetHeader>
        <div className="p-4">{children}</div>
      </SheetContent>
    </Sheet>
  );
};
