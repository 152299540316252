import type { FC } from 'react';
import { BRAND_OPTIONS } from '@/utils/consts';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import type { BrandOption } from '@/types/color';
import {
  PopoverContent,
  PopoverPrimitiveRoot,
  PopoverTrigger,
  PopoverItem,
} from '@/components/primitives/Popover';
import { Button } from '@/components/primitives/Button';
import { useState } from 'react';
import { useDispatch } from '@/store/utils';
import { getBrandConfiguration } from '@/features/configuration/configurationActions';

const BrandSelect: FC = () => {
  const [selectedBrand, setSelectedBrand] = useState<BrandOption>(
    BRAND_OPTIONS[0]
  );
  const dispatch = useDispatch();

  const handleBrandChange = (brand: BrandOption) => {
    setSelectedBrand(brand);
    dispatch(getBrandConfiguration(brand.value));
  };

  return (
    <PopoverPrimitiveRoot>
      <PopoverTrigger asChild>
        <Button
          data-testid="brand-select-trigger"
          variant="ghost"
          className="group flex items-center gap-2 px-0"
        >
          <Typography variant="button">{selectedBrand.label}</Typography>
          <Icons.chevronDown
            height={16}
            width={16}
            className="transition-transform duration-200 group-data-[state=open]:rotate-180"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-40" align="end">
        {BRAND_OPTIONS.map((option) => (
          <PopoverItem
            key={option.value}
            active={option.value === selectedBrand.value}
            onClick={() => handleBrandChange(option)}
            data-testid={`brand-select-choose-${option.value}`}
          >
            <Typography variant="body1">{option.label}</Typography>
            {option.value === selectedBrand.value && (
              <Icons.checkCircle
                height={16}
                width={16}
                className="text-success-text"
              />
            )}
          </PopoverItem>
        ))}
      </PopoverContent>
    </PopoverPrimitiveRoot>
  );
};

export default BrandSelect;
