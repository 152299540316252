import { type FC, useCallback, useState } from 'react';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import { Typography } from '@/components/primitives/Typography';
import { useDispatch, useSelector } from '@/store/utils';
import { Button } from '@/components/primitives/Button';
import useBookingSummaryData from '@/hooks/useBookingSummaryData';
import RefundModal from '@/components/manage/refund/RefundModal';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import RegularBookingSummary from '@/components/booking-summary/RegularBookingSummary';
import MobileBookingSummary from '@/components/booking-summary/MobileBookingSummary';
import { useBookingRefundEligibleTrips } from '@/hooks/useBookingRefundEligibleTrips';
import { manageFlowBookingSelector } from '@/features/manage/manageSelectors';
import { Flow } from '@/types/booking';
import { clearManageFlowBooking } from '@/features/manage/manageActions';
import Footer from '@/components/Footer';
import BookedTripsDetails from '@/components/manage/BookedTripsDetails';
import TravelPasses from '@/components/manage/travel-passes/TravelPasses';
import Divider from '@/components/primitives/Divider';
import { useTranslation } from 'react-i18next';
import i18nInstance, { NAMESPACE } from '@/i18n';
import { BackgroundThemeProvider } from '@/context/BackgroundThemeContextProvider';

interface BookingDetailsProps {}

export const BookingDetails: FC<BookingDetailsProps> = () => {
  const dispatch = useDispatch();
  const booking = useSelector(manageFlowBookingSelector);
  const summaryData = useBookingSummaryData(booking, [
    'CONFIRMED',
    'EXCHANGED',
    'REFUNDED',
    'FULFILLED',
  ]);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const { refundEligibleOutboundTrips, refundEligibleInboundTrips } =
    useBookingRefundEligibleTrips(booking);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const { t } = useTranslation(NAMESPACE, {
    i18n: i18nInstance,
  });

  const handleGoBack = useCallback(() => {
    dispatch(clearManageFlowBooking());
  }, [dispatch]);

  return (
    <div className="mb-28 tablet:mb-16">
      <div
        id="go-back-section"
        className="mx-auto flex max-w-laptop items-center gap-2 px-5 py-6"
      >
        <Button
          data-testid="go-back-button"
          className="-ml-2 p-2"
          variant="icon"
          aria-label={t('text.goBack', 'Go back')}
          onClick={handleGoBack}
        >
          <Icons.arrowLeft />
        </Button>

        <div className="flex items-baseline gap-1">
          <Typography variant={isLaptopOrBigger ? 'heading1-light' : 'body1'}>
            <>
              <TransText i18nKey="booking" />
              &#58;
            </>
          </Typography>
          <Typography variant={isLaptopOrBigger ? 'heading1' : 'subtitle'}>
            {booking?.code}
          </Typography>
        </div>
      </div>
      <Divider />
      <div className="mx-auto mt-8 flex max-w-laptop flex-col px-5 laptop:flex-row laptop:gap-10">
        <BackgroundThemeProvider backgroundTheme="light">
          {booking?.bookedTrips?.length ? (
            <BookedTripsDetails />
          ) : (
            <TravelPasses />
          )}
        </BackgroundThemeProvider>
        {isLaptopOrBigger && (
          <div className="flex flex-col gap-7">
            <Typography variant="heading2" asChild>
              <h1>
                <TransText i18nKey="bookingSummary" />
              </h1>
            </Typography>
            <RegularBookingSummary {...summaryData} flow={Flow.manageBooking} />
          </div>
        )}
      </div>
      <Footer
        content={
          !isLaptopOrBigger && (
            <MobileBookingSummary {...summaryData} flow={Flow.manageBooking} />
          )
        }
        actionButtons={
          (!!refundEligibleOutboundTrips.length ||
            !!refundEligibleInboundTrips.length) && (
            <Button
              fullWidth
              size="regular"
              variant="secondary"
              data-testid="refund-button"
              className="rounded-lg laptop:h-11 laptop:w-auto"
              onClick={() => setIsRefundModalOpen(true)}
            >
              <Icons.ticketReturn />
              <Typography variant="button">
                <TransText i18nKey="refund" />
              </Typography>
            </Button>
          )
        }
      />
      <RefundModal
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
      />
    </div>
  );
};
