import { useContext } from 'react';
import { BackgroundThemeContext } from '@/context/BackgroundThemeContextProvider';
import { useSelector } from '@/store/utils';

export const useBackgroundTheme = () => {
  const { backgroundTheme } = useSelector((state) => state.configuration.brand);
  const localBackgroundTheme = useContext(BackgroundThemeContext);

  // If the local background theme is not set, use the global background theme
  return {
    backgroundTheme: localBackgroundTheme ?? backgroundTheme,
  };
};
