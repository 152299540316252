import { type FC, useEffect, useState } from 'react';
import { Loadable } from '@/components/Loadable';
import { getConsents } from '@/features/purchase/purchaseService';
import type { ConsentsValues } from '@/utils/zod/schema';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import sanitizeMarkup from '@/utils/sanitizeMarkup';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/primitives/Form';
import { cn } from '@/utils/cn';
import { Checkbox } from '@/components/primitives/Checkbox';

interface ConsentsProps {}

const Consents: FC<ConsentsProps> = () => {
  const [loading, setLoading] = useState(true);
  const consentsForm = useFormContext<ConsentsValues>();
  const {
    control,
    setValue,
    formState: { errors },
  } = consentsForm;
  const {
    fields: consents,
    append,
    update,
  } = useFieldArray({
    control,
    name: 'consents',
  });

  useEffect(() => {
    const fetchConsents = async () => {
      const consents = await getConsents();
      setValue('consents', []);
      consents.items?.forEach(({ id, isMandatory, description, code }) => {
        append({
          isMandatory,
          description,
          checked: false,
          id,
          code,
        });
      });
      setLoading(false);
    };

    fetchConsents();
  }, [append, setValue]);

  return (
    <Loadable loading={loading}>
      <div className="flex flex-col gap-4" data-testid="consents">
        {consents.map((consent, index) => {
          const markup = consent.description || '';
          const hasError = errors.consents?.[index]?.checked;

          return (
            <FormField
              control={control}
              name={`consents.${index}.checked`}
              key={index}
              render={() => (
                <FormItem>
                  <FormControl label={null}>
                    <div className="flex items-center gap-3">
                      <Checkbox
                        className={cn('h-6 w-6', {
                          'border-error-text': hasError,
                        })}
                        checked={consent.checked}
                        onCheckedChange={(checked) =>
                          update(index, {
                            ...consent,
                            checked: checked === true,
                          })
                        }
                      />
                      <FormLabel>
                        <div
                          key={consent.id}
                          className="flex items-baseline gap-1"
                        >
                          {markup ? (
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: sanitizeMarkup(markup),
                              }}
                              variant="body1"
                              className={cn(
                                'prose text-dark prose-p:my-0 prose-p:text-dark prose-a:text-blue-600 prose-a:no-underline',
                                {
                                  'text-error-text': hasError,
                                }
                              )}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              className={cn('text-dark', {
                                'text-error-text': hasError,
                              })}
                            >
                              <TransText i18nKey="undefinedConsentDescription" />
                            </Typography>
                          )}
                          {!consent.isMandatory && (
                            <span>
                              <Typography
                                variant="body2"
                                className="text-neutral"
                              >
                                (<TransText i18nKey="optional" />)
                              </Typography>
                            </span>
                          )}
                        </div>
                      </FormLabel>
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          );
        })}
      </div>
    </Loadable>
  );
};

export default Consents;
