import { OpenAPIClientAxios } from 'openapi-client-axios';
import type { Client as AgentClient } from '@/types/schemas/agent-schema';
import type { Client as CustomerProfileClient } from '@/types/schemas/customer-profile-schema';
import authorizationMiddleware from '@/api/middleware/authorization';
import languageMiddleware from '@/api/middleware/language';
import dataExtractMiddleware from '@/api/middleware/dataExtract';

let apiInstance: {
  agentApi: AgentClient;
  customerProfileApi: CustomerProfileClient;
} | null = null;

const initClients = async () => {
  const agentApiDefinition = new OpenAPIClientAxios({
    definition: `${import.meta.env.VITE_APP_BASE_URL}/swagger/agent-swagger.json`,
    withServer: {
      url: `${import.meta.env.VITE_APP_PROXY_TARGET}/agent`,
    },
  });
  const agentApi = await agentApiDefinition.init<AgentClient>();
  configureMiddleware(agentApi);

  const customerProfileApiDefinition = new OpenAPIClientAxios({
    definition: `${import.meta.env.VITE_APP_BASE_URL}/swagger/customer-profile-swagger.json`,
    withServer: {
      url: `${import.meta.env.VITE_APP_PROXY_TARGET}/customer-profile`,
    },
  });
  const customerProfileApi =
    await customerProfileApiDefinition.init<CustomerProfileClient>();
  configureMiddleware(customerProfileApi);

  return {
    agentApi,
    customerProfileApi,
  };
};

const configureMiddleware = (client: AgentClient | CustomerProfileClient) => {
  client.interceptors.response.use(dataExtractMiddleware);
  client.interceptors.request.use(authorizationMiddleware);
  client.interceptors.request.use(languageMiddleware);
};

export default async function getApiInstance() {
  if (!apiInstance) {
    apiInstance = await initClients();
  }

  return apiInstance;
}
