import { type FC, useEffect } from 'react';
import { usePurchaseSteps } from '@/hooks/usePurchaseSteps';
import { cn } from '@/utils/cn';
import { STEP } from '@/utils/consts';
import { SearchWidgetWrapper } from '@/components/search-widget';
import { SearchWidgetContextProvider } from '@/context/SearchWidgetContextProvider';
import { useDispatch, useSelector } from '@/store/utils';
import { purchaseFlowBookingIdSelector } from '@/features/purchase/purchaseSelectors';
import {
  getPurchaseFlowBookingById,
  setActiveStep,
} from '@/features/purchase/purchaseActions';
import { persistor } from '@/store';
import { BackgroundThemeProvider } from '@/context/BackgroundThemeContextProvider';

interface PurchaseProps {}

const Purchase: FC<PurchaseProps> = () => {
  const dispatch = useDispatch();
  const { steps, activeStep } = usePurchaseSteps();
  const purchaseBookingId = useSelector(purchaseFlowBookingIdSelector);

  useEffect(() => {
    if (purchaseBookingId) {
      dispatch(getPurchaseFlowBookingById(purchaseBookingId))
        .unwrap()
        .then(() => {
          dispatch(setActiveStep(STEP.Checkout));
        })
        .catch(() => {
          persistor.purge();
        });
    }
  }, [dispatch, purchaseBookingId]);

  return (
    <SearchWidgetContextProvider>
      <div className="sticky top-0 z-50 bg-background">
        {STEP.Checkout !== activeStep && <SearchWidgetWrapper />}
      </div>
      <div
        className={cn('mx-auto mb-28 w-full max-w-laptop p-5 tablet:mb-16', {
          ['pt-0']: activeStep === STEP.Checkout, // search widget is hidden and content can be closer to header
          ['mb-0 tablet:mb-0']: activeStep === STEP.OutboundJourney, // footer is hidden for outbound journey so bottom padding not needed
          ['mb-60 tablet:mb-24']: activeStep === STEP.Checkout, // booking summary on top of footer takes extra space so additional bottom padding is needed
        })}
      >
        <BackgroundThemeProvider backgroundTheme="light">
          {steps[activeStep]}
        </BackgroundThemeProvider>
      </div>
    </SearchWidgetContextProvider>
  );
};

export default Purchase;
