import React, { createContext } from 'react';
import type { BackgroundTheme } from '@/types/color';
import { DEFAULT_BACKGROUND_THEME } from '@/utils/consts';

export const BackgroundThemeContext = createContext<BackgroundTheme>(
  DEFAULT_BACKGROUND_THEME
);

interface BackgroundThemeProviderProps {
  children: React.ReactNode;
  backgroundTheme: BackgroundTheme;
}

export const BackgroundThemeProvider: React.FC<
  BackgroundThemeProviderProps
> = ({ children, backgroundTheme }) => {
  return (
    <BackgroundThemeContext.Provider value={backgroundTheme}>
      {children}
    </BackgroundThemeContext.Provider>
  );
};
