import Divider from '@/components/primitives/Divider';
import { formatDay, formatTime } from '@/utils/date-time';
import { Icons } from '@/components/icons';
import {
  AccordionContent,
  AccordionItem,
  AccordionPrimitiveRoot,
  AccordionTrigger,
} from '@/components/primitives/Accordion';
import type { FC } from 'react';
import { useState } from 'react';
import LegStop from '@/components/purchase/journey/LegStop';
import LegIntermediateStop from '@/components/purchase/journey/LegIntermediateStop';
import type { LegDTO } from '@/types/dto';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { ServiceAttributeIcon } from '@/components/icons/ServiceAttributeIcon';

interface StopDetailsProps {
  leg: LegDTO;
}

const StopDetails: FC<StopDetailsProps> = ({ leg }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState('');
  const departureDay = formatDay(leg.departureTime);
  const arrivalDay = formatDay(leg.arrivalTime);
  const departureTime = formatTime(leg.departureTime);
  const arrivalTime = formatTime(leg.arrivalTime);

  return (
    <div className="flex flex-col gap-4 laptop:w-[300px]">
      <div className="flex gap-3">
        <div className="flex flex-col items-center justify-between px-1 py-0.5">
          <div className="flex h-5 w-5 items-center justify-center">
            <Icons.bus width={16} height={16} className="text-primary" />
          </div>
          <div className="h-full w-0.5 bg-neutral-light" />
          <div className="flex h-3 w-3 items-center justify-center">
            <Icons.destination className="text-primary" />
          </div>
        </div>
        <div className="flex w-full flex-col gap-2">
          {leg.originStop && (
            <LegStop
              stop={leg.originStop}
              time={departureTime}
              day={departureDay}
            />
          )}
          {!!leg.intermediateStops?.length && (
            <AccordionPrimitiveRoot
              type="single"
              value={isAccordionOpen}
              onValueChange={setIsAccordionOpen}
              collapsible
            >
              <AccordionItem value="open" className="border-none">
                <AccordionTrigger className="justify-start gap-1 p-0 text-primary hover:no-underline">
                  <Typography variant="body2-bold">
                    <TransText
                      i18nKey="stopsCount"
                      values={{ count: leg.intermediateStops.length }}
                    />
                  </Typography>
                  {isAccordionOpen && (
                    <div className="absolute right-20 grid w-32 grid-cols-2 laptop:right-0">
                      <Typography
                        variant="body2-bold"
                        className="justify-self-center text-dark"
                      >
                        <TransText i18nKey="arrival" />
                      </Typography>
                      <Typography
                        variant="body2-bold"
                        className="justify-self-center text-dark"
                      >
                        <TransText i18nKey="departure" />
                      </Typography>
                    </div>
                  )}
                </AccordionTrigger>
                <AccordionContent className="flex flex-col gap-2 py-2">
                  <Divider />
                  {leg.intermediateStops.map((stop) => (
                    <LegIntermediateStop
                      key={`${stop.code}_${stop.name}`}
                      stop={stop}
                    />
                  ))}
                  <Divider />
                </AccordionContent>
              </AccordionItem>
            </AccordionPrimitiveRoot>
          )}
          {leg.destinationStop && (
            <LegStop
              stop={leg.destinationStop}
              time={arrivalTime}
              day={arrivalDay}
            />
          )}
        </div>
      </div>
      {/* TODO: Get carrier's info from somewhere */}
      <div className="flex flex-col gap-2">
        <div className="flex text-dark">
          <div className="flex items-center gap-1 rounded-l bg-primary-light px-2">
            <Icons.bus width={12} height={12} />
            <Typography variant="body2-bold">{leg.serviceCode}</Typography>
          </div>
          <div className="flex w-fit gap-2 rounded-r bg-primary-pale p-1.5">
            {leg.serviceAttributes?.map((attr) => (
              <ServiceAttributeIcon key={attr.code} {...attr} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StopDetails;
