import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/utils/cn';
import { Icons } from '@/components/icons';
import { useThemedColors } from '@/hooks/useThemedColors';
import { typographyVariants } from '@/components/primitives/Typography';
import { isLightBackground } from '@/utils/color';
import { useBackgroundTheme } from '@/hooks/useBackgroundTheme';

const checkboxVariants = cva(
  'peer shrink-0 rounded-sm border ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-tint focus-visible:ring-offset-2',
  {
    variants: {
      size: {
        sm: 'h-4 w-4',
        md: 'h-5 w-5',
        lg: 'h-6 w-6',
      },
      error: {
        true: [
          'border-error-border',
          'bg-error-surface text-error-text',
          'hover:border-error-border hover:bg-error-surface',
          'data-[state=checked]:bg-error-surface data-[state=checked]:text-error-text',
          'data-[state=indeterminate]:bg-error-surface data-[state=indeterminate]:text-error-text',
        ],
        false: '',
      },
    },
    defaultVariants: {
      size: 'sm',
      error: false,
    },
  }
);

const iconVariants = cva('', {
  variants: {
    size: {
      sm: 'h-3 w-3',
      md: 'h-4 w-4',
      lg: 'h-5 w-5',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    VariantProps<typeof checkboxVariants> {
  indeterminate?: boolean;
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    { className, indeterminate, checked, children, id, size, error, ...props },
    ref
  ) => {
    const { getCheckboxColors, getCheckboxLabelColors } = useThemedColors();
    const { backgroundTheme } = useBackgroundTheme();
    const generatedId = React.useId();
    const checkboxId = id || generatedId;

    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="group inline-flex items-center">
          <CheckboxPrimitive.Root
            ref={ref}
            id={checkboxId}
            className={cn(
              checkboxVariants({ size, error }),
              !error && getCheckboxColors(),
              className
            )}
            checked={indeterminate ? 'indeterminate' : checked}
            {...props}
          >
            <CheckboxPrimitive.Indicator
              className={cn('flex items-center justify-center text-current')}
            >
              {indeterminate ? (
                <Icons.minus className={iconVariants({ size })} />
              ) : (
                <Icons.check className={iconVariants({ size })} />
              )}
            </CheckboxPrimitive.Indicator>
          </CheckboxPrimitive.Root>
          {children && (
            <label
              htmlFor={checkboxId}
              className={cn(
                'ml-2 cursor-pointer peer-disabled:cursor-not-allowed',
                typographyVariants({
                  variant: size === 'lg' ? 'body1' : 'body2',
                }),
                error
                  ? 'text-error-text group-hover:text-error-text'
                  : [
                      getCheckboxLabelColors(),
                      isLightBackground(backgroundTheme) &&
                        'group-hover:text-primary-dark',
                    ]
              )}
            >
              {children}
            </label>
          )}
        </div>
      </div>
    );
  }
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
