import type { FC } from 'react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '@/components/icons';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from '@/components/primitives/NavigationMenu';
import type { NavigationItem } from '@/types/navigation';
import { Typography } from '@/components/primitives/Typography';
import Divider from '@/components/primitives/Divider';
import RegularLanguageSelect from '@/components/header/language-select/RegularLanguageSelect';
import { Link } from '@/components/primitives/Link';
import { Button } from '@/components/primitives/Button';
import BrandSelect from '../brand-select/BrandSelect';
import { isLocalDevelopment } from '@/utils/isLocalDev';

type RegularNavMenuProps = {
  menuItems: Array<NavigationItem>;
};

const RegularNavMenu: FC<RegularNavMenuProps> = ({ menuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      className="mx-auto flex w-full items-center justify-between text-white"
      data-testid="navbar-regular"
    >
      <div className="flex items-center gap-6">
        <Link to="/" aria-label="Hero">
          <Icons.turnitLogo />
        </Link>
        <nav className="flex items-center">
          <NavigationMenu>
            <NavigationMenuList className="flex items-center gap-4">
              {menuItems.map((item, index) => (
                <React.Fragment key={item.path}>
                  <NavigationMenuItem>
                    <Link
                      to={item.path}
                      onClick={item.onMenuItemClick}
                      active={location.pathname === item.path}
                    >
                      <Typography variant="button" asChild>
                        {item.name}
                      </Typography>
                    </Link>
                  </NavigationMenuItem>
                  {index < menuItems.length - 1 && (
                    <Divider
                      orientation="vertical"
                      className="h-4"
                      variant="strong"
                    />
                  )}
                </React.Fragment>
              ))}
            </NavigationMenuList>
          </NavigationMenu>
        </nav>
      </div>
      <div className="flex items-center gap-4">
        {isLocalDevelopment() && (
          <>
            <BrandSelect />
            <Divider orientation="vertical" className="h-4" variant="strong" />
          </>
        )}
        <Button
          data-testid="my-account-button"
          onClick={() => navigate('/login')}
          variant="icon"
        >
          <Icons.user />
        </Button>
        <Divider orientation="vertical" className="h-4" variant="strong" />
        <RegularLanguageSelect />
      </div>
    </div>
  );
};

export default RegularNavMenu;
