import { type FC, useState } from 'react';
import SignupForm from '@/routes/signup/SignupForm';
import SignupMethod from '@/routes/signup/SignupMethod';

interface SignupProps {}

const Signup: FC<SignupProps> = () => {
  const [email, setEmail] = useState<string>('');

  return email ? (
    <SignupForm email={email} />
  ) : (
    <SignupMethod setEmail={setEmail} />
  );
};

export default Signup;
