import { z } from 'zod';
import { paymentMethods } from '@/utils/consts';
import type { TFunction } from 'i18next';
import validationMessage from '@/utils/zod/validationMessage';

export const createPassengerSchema = (t: TFunction) => {
  const { mustBeFilled, invalidEmailFormat } = validationMessage(t);

  return z.object({
    id: z.string(),
    externalReference: z.string(),
    firstName: z.string().min(1, mustBeFilled),
    lastName: z.string().min(1, mustBeFilled),
    email: z.string().min(1, mustBeFilled).email(invalidEmailFormat),
    phone: z.object({
      number: z.string().min(1, mustBeFilled),
    }),
    age: z.number().nullable(),
  });
};
export type PassengerValues = z.infer<ReturnType<typeof createPassengerSchema>>;

export const createPurchaserSchema = (t: TFunction) => {
  const { mustBeFilled, invalidEmailFormat } = validationMessage(t);

  return z.object({
    externalReference: z.string().optional(),
    firstName: z.string().min(1, mustBeFilled),
    lastName: z.string().min(1, mustBeFilled),
    email: z.string().min(1, mustBeFilled).email(invalidEmailFormat),
    phone: z.object({
      number: z.string().min(1, mustBeFilled),
    }),
  });
};
export type PurchaserValues = z.infer<ReturnType<typeof createPurchaserSchema>>;

export const createPaymentMethodSchema = (t: TFunction) => {
  const { invalidPaymentMethod } = validationMessage(t);

  return z.object({
    id: z
      .string()
      .refine(
        (val) => paymentMethods.find(({ id }) => val === id),
        invalidPaymentMethod
      ),
  });
};
export type PaymentMethodValues = z.infer<
  ReturnType<typeof createPaymentMethodSchema>
>;

export const createConsentsSchema = (t: TFunction) => {
  const { mustAgreeBeforeContinuing } = validationMessage(t);

  return z.object({
    consents: z
      .array(
        z.object({
          isMandatory: z.boolean(),
          description: z.string().optional(),
          checked: z.boolean(),
          id: z.union([z.number(), z.string()]),
          code: z.union([z.string(), z.undefined()]),
        })
      )
      .superRefine((consents, ctx) => {
        consents.forEach((consent, index) => {
          if (consent.isMandatory && !consent.checked) {
            ctx.addIssue({
              path: [index, 'checked'],
              code: z.ZodIssueCode.custom,
              ...mustAgreeBeforeContinuing,
            });
          }
        });
      }),
  });
};
export type ConsentsValues = z.infer<ReturnType<typeof createConsentsSchema>>;

export const createAncillarySchema = (t: TFunction) => {
  const { minimumOne, mustChooseLeg } = validationMessage(t);

  return z.object({
    legId: z.string().min(1, mustChooseLeg),
    selectedAncillaries: z
      .array(
        z
          .object({
            id: z.string(),
            bookedOfferId: z.string(),
            additionalOfferId: z.string(),
            amount: z.number(),
            passengersExternalReferences: z
              .array(z.string().optional())
              .min(1, minimumOne),
          })
          .optional()
      )
      .min(1, minimumOne),
  });
};
export type AncillaryValues = z.infer<ReturnType<typeof createAncillarySchema>>;

export const createFindBookingSchema = (t: TFunction) => {
  const { mustBeFilled, invalidEmailFormat } = validationMessage(t);

  return z.object({
    email: z.string().min(1, mustBeFilled).email(invalidEmailFormat),
    bookingNumber: z.string().min(1, mustBeFilled),
  });
};
export type FindBookingValues = z.infer<
  ReturnType<typeof createFindBookingSchema>
>;

export const createLoginSchema = (t: TFunction) => {
  const { mustBeFilled, invalidEmailFormat, maximum64 } = validationMessage(t);

  return z.object({
    email: z.string().min(1, mustBeFilled).email(invalidEmailFormat),
    password: z.string().min(8, mustBeFilled).max(64, maximum64),
  });
};
export type LoginValues = z.infer<ReturnType<typeof createLoginSchema>>;

export const createSignupMethodSchema = (t: TFunction) => {
  const { mustBeFilled, invalidEmailFormat } = validationMessage(t);

  return z.object({
    email: z.string().min(1, mustBeFilled).email(invalidEmailFormat),
  });
};
export type SignupMethodValues = z.infer<
  ReturnType<typeof createSignupMethodSchema>
>;

export const createSignupSchema = (t: TFunction) => {
  const {
    mustBeFilled,
    minimumEight,
    maximum64,
    mustAgreeBeforeContinuing,
    passwordsMustMatch,
  } = validationMessage(t);

  return z
    .object({
      firstName: z.string().min(1, mustBeFilled),
      lastName: z.string().min(1, mustBeFilled),
      phoneNumber: z.string().optional(),
      password: z.string().min(8, minimumEight).max(64, maximum64),
      repeatPassword: z.string().min(8, minimumEight).max(64, maximum64),
      consents: z.array(
        z.object({
          isMandatory: z.boolean(),
          description: z.string().optional(),
          checked: z.boolean(),
          id: z.union([z.number(), z.string()]),
          code: z.union([z.string(), z.undefined()]),
        })
      ),
    })
    .superRefine(({ password, repeatPassword, consents }, ctx) => {
      if (password !== repeatPassword) {
        ctx.addIssue({
          path: ['password'],
          code: z.ZodIssueCode.custom,
          ...passwordsMustMatch,
        });
        ctx.addIssue({
          path: ['repeatPassword'],
          code: z.ZodIssueCode.custom,
          ...passwordsMustMatch,
        });
      }

      consents.forEach((consent, index) => {
        if (consent.isMandatory && !consent.checked) {
          ctx.addIssue({
            path: ['consents', index, 'checked'],
            code: z.ZodIssueCode.custom,
            ...mustAgreeBeforeContinuing,
          });
        }
      });
    });
};
export type SignupValues = z.infer<ReturnType<typeof createSignupSchema>>;
