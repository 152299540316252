import type { SignupValues } from '@/utils/zod/schema';
import getApiInstance from '@/api';
import type { CreateCustomerProfileRequestBodyDTO } from '@/types/dto';

export const createCustomerProfile = async (
  values: SignupValues & {
    email: CreateCustomerProfileRequestBodyDTO['email'];
  }
) => {
  const api = (await getApiInstance()).agentApi;
  const _values = {
    ...values,
    consents: values.consents.map(({ code, checked }) => ({
      code,
      decisionId: checked
        ? 'CONSENT_DECISION.ACCEPTED'
        : 'CONSENT_DECISION.NOT_ACCEPTED',
    })),
    isMemberOfLoyaltyProgram: false,
  };

  return (
    await api.CustomersProfilesCreate_CreateCustomerProfile(null, _values)
  ).data.id;
};
