import type { FC } from 'react';
import { useState } from 'react';
import type { OfferDTO } from '@/types/dto';
import type { OfferMapByLegId, OfferMapItem } from '@/types/offer';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import { cn } from '@/utils/cn';
import Divider from '@/components/primitives/Divider';
import { useDispatch, useSelector } from '@/store/utils';
import type { ActionCreatorWithPayload, Selector } from '@reduxjs/toolkit';
import type { RootState } from '@/store';
import { TransText } from '@/i18n/trans/text';
import Refundability from '@/components/purchase/card/Refundability';
import Changeability from '@/components/purchase/card/Changeability';
import { Radio } from '@/components/primitives/Radio';
import OfferDetailsDialog from './OfferDetailsDialog';
import { Button } from '@/components/primitives/Button';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import currencySymbolMap from '@/utils/currencySymbolMap';

interface OfferCardProps {
  offer: OfferDTO;
  legId?: string;
  journeyDetails: {
    originStopName?: string;
    destinationStopName?: string;
  };
  updateSelectedOfferMap: ActionCreatorWithPayload<OfferMapItem>;
  selectedOfferMapByLegIdSelector: Selector<RootState, OfferMapByLegId>;
}

const OfferCard: FC<OfferCardProps> = ({
  offer,
  legId,
  journeyDetails,
  updateSelectedOfferMap,
  selectedOfferMapByLegIdSelector,
}) => {
  const dispatch = useDispatch();
  const selectedOfferMapByLegId = useSelector(selectedOfferMapByLegIdSelector);
  const selectedOffer = legId ? selectedOfferMapByLegId[legId] : null;
  const isSelected = Boolean(selectedOffer?.id === offer.id);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  if (!offer) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex w-full flex-col justify-between overflow-hidden rounded-lg border border-primary text-dark hover:cursor-pointer hover:bg-primary-pale',
        { 'border-2 bg-primary-light': isSelected }
      )}
      onClick={() => {
        if (offer.id && offer.price && legId) {
          dispatch(updateSelectedOfferMap({ legId, offer }));
        }
      }}
    >
      <div className="flex flex-col gap-1 p-2 laptop:gap-0">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row gap-2">
            <Radio checked={isSelected} readOnly aria-label="Select offer" />
            <Typography variant="body1-bold">
              <span>{offer.productSummary}</span>
            </Typography>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <Typography variant="body1-bold" className="flex pl-7 text-primary">
            {offer.price?.amount}
            {offer.price?.currency && currencySymbolMap[offer.price?.currency]}
          </Typography>
          <Button
            variant="ghost"
            data-testid="fare-offer-details-trigger"
            className="h-auto px-0"
            onClick={(e) => {
              e.stopPropagation();
              setIsDialogOpen(true);
            }}
          >
            <Icons.infoCircle />
            <Typography variant="body2">
              <TransText i18nKey="details" />
            </Typography>
          </Button>
        </div>
        <Divider className="my-3" variant={isSelected ? 'medium' : 'regular'} />
        <div>
          <div className="flex flex-row gap-1">
            <Refundability condition={offer.refundable === 'YES'} />
            <Changeability condition={offer.exchangeable === 'YES'} />
          </div>
          <div className="mt-2 flex flex-row gap-1">
            <Icons.users className="text-primary" />
            <Typography variant="body2">
              <TransText i18nKey="priceIsForAllPassengers" />
            </Typography>
          </div>
        </div>
      </div>
      {!isLaptopOrBigger && (
        <Button
          variant={isSelected ? 'primary' : 'secondary'}
          data-testid="select-offer"
          className="mt-1 w-full rounded-none border-0"
        >
          {isSelected && (
            <Icons.checkCircle width={20} height={20} className="self-center" />
          )}
          <Typography variant="body1-bold">
            <TransText i18nKey={isSelected ? 'selected' : 'select'} />
          </Typography>
        </Button>
      )}
      <OfferDetailsDialog
        offer={offer}
        journeyDetails={journeyDetails}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </div>
  );
};

export default OfferCard;
