import type { BackgroundTheme } from '@/types/color';
import type { PaymentTypeDTO } from '@/types/dto';

export const TURNIT_BRAND_COLORS = {
  dark: '#010845',
  primary: '#003FBF',
  tint: '#8619FF',
  accent: '#FFF250',
  secondary: '#003FBF',
  secondaryContrast: '#FFFFFF',
};

export const ARENAWAYS_BRAND_COLORS = {
  dark: '#0E2A3D',
  primary: '#127856',
  tint: '#0C8097',
  accent: '#F6BB29',
  secondary: '#F6BB29',
  secondaryContrast: '#00263E',
};

export const BRAND_OPTIONS = [
  {
    value: 'server',
    label: 'Dynamic',
  },
  {
    value: 'turnit',
    label: 'Turnit',
  },
  {
    value: 'arenaways',
    label: 'Arenaways',
  },
];

export const DEFAULT_BRAND_COLORS = TURNIT_BRAND_COLORS;
export const DEFAULT_BACKGROUND_THEME: BackgroundTheme = 'dark';
export const DEFAULT_RADIUS = '8px';

export enum STEP {
  PreSearchSubmit,
  OutboundJourney,
  OutboundOffer,
  InboundJourney,
  InboundOffer,
  NonTripOffer,
  Checkout,
}

export const paymentMethods: Array<PaymentTypeDTO> = [
  {
    id: 'SALES_POINT_PAYMENT_TYPE.EXTERNAL_3RD_PARTY',
    name: 'External',
    isNegativePaymentAllowed: false,
  },
];

export enum TransportType {
  HIGH_SPEED_TRAIN = 'HIGH_SPEED_TRAIN',
  INTERCITY_REGIONAL = 'INTERCITY_REGIONAL',
  LONG_DISTANCE = 'LONG_DISTANCE',
  LOCAL_TRAIN = 'LOCAL_TRAIN',
  LOCAL_BUS = 'LOCAL_BUS',
  TRAIN = 'TRAIN',
  BUS = 'BUS',
}
