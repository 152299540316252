import Divider from '@/components/primitives/Divider';
import { MobileSearchWidget } from '@/components/search-widget/MobileSearchWidget';
import { RegularSearchWidget } from '@/components/search-widget/RegularSearchWidget';
import {
  purchaseBookingLoadingSelector,
  inboundJourneysLoadingSelector,
  outboundJourneysLoadingSelector,
  configurationLoadingSelector,
} from '@/features/loading/loadingSelectors';
import { useSearchWidgetContext } from '@/hooks/useSearchWidgetContext';
import { useSelector } from '@/store/utils';
import { breakpoints } from '@/utils/breakpoints';
import { SearchWidget } from '@turnit-ride-ui/webshop-search-widget';
import { getQueryParams } from '@turnit-ride-ui/webshop-search-widget/widget/utils/query';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { purchaseFlowBookingIdSelector } from '@/features/purchase/purchaseSelectors';
import { brandSelector } from '@/features/configuration/configurationSelector';

export const SearchWidgetWrapper = () => {
  const { brandColors } = useSelector(brandSelector);
  const { handleSubmit } = useSearchWidgetContext();
  const configurationLoading = useSelector(configurationLoadingSelector);
  const purchaseBookingId = useSelector(purchaseFlowBookingIdSelector);
  const outboundJourneysLoading = useSelector(outboundJourneysLoadingSelector);
  const inboundJourneysLoading = useSelector(inboundJourneysLoadingSelector);
  const journeysLoading = useMemo(
    () => outboundJourneysLoading && inboundJourneysLoading,
    [inboundJourneysLoading, outboundJourneysLoading]
  );
  const bookingLoading = useSelector(purchaseBookingLoadingSelector);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const [isAutoSubmitted, setIsAutoSubmitted] = useState(false);
  const queryParams = useMemo(() => getQueryParams(), []);

  useEffect(() => {
    if (!configurationLoading && !purchaseBookingId) {
      const {
        origin,
        destination,
        departureDate,
        returnDate,
        passengers,
        productType,
      } = queryParams;

      if (
        origin?.value &&
        destination?.value &&
        departureDate &&
        passengers &&
        productType &&
        !isAutoSubmitted
      ) {
        handleSubmit({
          origin,
          destination,
          departureDate,
          returnDate,
          passengers,
          productType,
        });
      }

      setIsAutoSubmitted(true);
    }
  }, [
    configurationLoading,
    handleSubmit,
    isAutoSubmitted,
    purchaseBookingId,
    queryParams,
  ]);

  const searchWidget = useMemo(
    () =>
      brandColors && (
        <SearchWidget
          baseUrl={import.meta.env.VITE_APP_PROXY_TARGET}
          onSubmit={handleSubmit}
          size="sm"
          isLoading={journeysLoading || bookingLoading}
          brandColors={brandColors}
        />
      ),
    [bookingLoading, handleSubmit, journeysLoading, brandColors]
  );

  return (
    <>
      {isLaptopOrBigger ? (
        <RegularSearchWidget>{searchWidget}</RegularSearchWidget>
      ) : (
        <MobileSearchWidget>{searchWidget}</MobileSearchWidget>
      )}
      <Divider />
    </>
  );
};
