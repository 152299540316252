import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import ConfirmModal from '@/components/ConfirmModal';

interface BookingExpiredDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onCancel: () => void;
}

const BookingExpiredDialog: FC<BookingExpiredDialogProps> = ({
  open,
  setOpen,
  onCancel,
}) => {
  return (
    <ConfirmModal
      open={open}
      setOpen={setOpen}
      onCancel={onCancel}
      title={
        <Typography variant="subtitle">
          <TransText i18nKey="bookingSessionExpired" />
        </Typography>
      }
      description={
        <Typography variant="paragraph" className="text-dark">
          <TransText i18nKey="bookingSessionExpiryDescription" />
        </Typography>
      }
      cancelLabel="backToJourneySearch"
    />
  );
};

export default BookingExpiredDialog;
