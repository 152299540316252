import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from '@/components/header';
import Purchase from '@/routes/purchase';
import * as Sentry from '@sentry/react';
import { Manage } from '@/routes/manage';
import { ToastContainer } from 'react-toastify';
import Success from '@/routes/success';
import { persistor } from '@/store';
import { PersistGate } from 'redux-persist/integration/react';
import { useDispatch } from '@/store/utils';
import { useEffect } from 'react';
import { getConfiguration } from '@/features/configuration/configurationActions';
import Login from '@/routes/login';
import Signup from '@/routes/signup';
import SignupConfirmation from '@/routes/signup/SignupConfirmation';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfiguration());
  }, [dispatch]);

  return (
    <div className="min-w-mobile">
      <ToastContainer
        position="top-center"
        theme="colored"
        hideProgressBar={true}
        closeOnClick
      />
      <BrowserRouter>
        <Header />
        <SentryRoutes>
          <Route
            path="/"
            element={
              <PersistGate loading={null} persistor={persistor}>
                <Purchase />
              </PersistGate>
            }
          />
          <Route path="/manage-booking" element={<Manage />} />
          <Route path="/success" element={<Success />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signup/confirmation" element={<SignupConfirmation />} />
        </SentryRoutes>
      </BrowserRouter>
    </div>
  );
}

export default App;
