import {
  AccordionContent,
  AccordionItem,
  AccordionPrimitiveRoot,
  AccordionTrigger,
} from '@/components/primitives/Accordion';
import { Typography } from '@/components/primitives/Typography';
import type { FC } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { PassengerValues } from '@/utils/zod/schema';
import { getPassengerName, getPassengerOtherDetails } from '@/utils/passenger';
import { TransText } from '@/i18n/trans/text';
import { InputField } from '@/components/form/InputField';
import { PhoneInputField } from '@/components/form/PhoneInputField';

interface PassengerFormProps {
  index: number;
}

const PassengerForm: FC<PassengerFormProps> = ({ index }) => {
  const { control, watch } = useFormContext<{
    passengers: Array<PassengerValues>;
  }>();

  const passenger = watch(`passengers.${index}`);

  return (
    <AccordionPrimitiveRoot
      type="single"
      collapsible
      className="m-0"
      defaultValue={index === 0 ? 'open' : ''}
    >
      <AccordionItem value="open" className="border-0">
        <AccordionTrigger className="px-1 text-primary hover:no-underline">
          <div className="mr-3 flex w-full items-baseline gap-2 text-start">
            <Typography
              variant="body1-bold"
              className="whitespace-normal break-all text-dark"
              asChild
            >
              <h3>{getPassengerName(passenger, index)}</h3>
            </Typography>
            <Typography variant="body2" className="text-neutral-light">
              {getPassengerOtherDetails(passenger)}
            </Typography>
          </div>
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-3 px-1 pb-0">
          <div className="grid grid-cols-1 gap-2 tablet:grid-cols-2 tablet:gap-4">
            <InputField
              control={control}
              name={`passengers.${index}.firstName`}
              label={<TransText i18nKey="firstName" />}
              required
            />
            <InputField
              control={control}
              name={`passengers.${index}.lastName`}
              label={<TransText i18nKey="lastName" />}
              required
            />
            <InputField
              control={control}
              name={`passengers.${index}.email`}
              label={<TransText i18nKey="email" />}
              required
            />
            <PhoneInputField
              control={control}
              name={`passengers.${index}.phone.number`}
              label={<TransText i18nKey="phoneNumber" />}
              required
            />
          </div>
        </AccordionContent>
      </AccordionItem>
    </AccordionPrimitiveRoot>
  );
};

export default PassengerForm;
