import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useTicketDownload } from '@/hooks/useTicketDownload';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import Divider from '@/components/primitives/Divider';
import { Button } from '@/components/primitives/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '@/store/utils';
import { Loadable } from '@/components/Loadable';
import { successLoadingSelector } from '@/features/loading/loadingSelectors';
import { usePkPassDownload } from '@/hooks/usePkPassDownload';
import { sendPurchaseConfirmation } from '@/features/purchase/purchaseService';
import { toast } from 'react-toastify';
import { STORAGE_KEYS, useStorage } from '@/hooks/useStorage';
import type { CheckoutSuccessSessionStorageData } from '@/types/session-storage';

const Success = () => {
  const navigate = useNavigate();
  const loading = useSelector(successLoadingSelector);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPkPassDownloading, setIsPkPassDownloading] = useState(false);
  const { value: successData, removeValue } =
    useStorage<CheckoutSuccessSessionStorageData>(
      sessionStorage,
      STORAGE_KEYS.CHECKOUT_SUCCESS_DATA
    );

  const handleTicketDownload = useTicketDownload({
    bookingId: successData?.bookingId,
    onStart: () => setIsDownloading(true),
    onFinish: () => setIsDownloading(false),
  });

  const handlePkPassDownload = usePkPassDownload({
    fulfillmentIds: successData?.fulfillmentIds,
    onStart: () => setIsPkPassDownloading(true),
    onFinish: () => setIsPkPassDownloading(false),
  });

  useEffect(() => {
    if (!successData?.bookingId || !successData?.payerEmail) {
      navigate('/', { replace: true });
      return;
    }

    sendPurchaseConfirmation(successData.bookingId, [
      successData.payerEmail,
    ]).catch(() => {
      toast.error(<TransText i18nKey="errorSendingEmail" />);
    });

    return () => {
      removeValue();
    };
  }, [navigate, removeValue, successData]);

  if (!successData?.bookingId) {
    return null;
  }

  return (
    <Loadable loading={loading}>
      <div className="mx-auto mt-10 max-w-[846px] p-5">
        <div className="grid grid-cols-1 gap-2 desktop:grid-cols-2 desktop:gap-10">
          <div className="flex flex-col">
            <div className="flex items-center gap-3">
              <Icons.checkCircle className="h-7 w-7" />
              <Typography
                variant={isLaptopOrBigger ? 'title' : 'mobile-title'}
                asChild
              >
                <h1>
                  <TransText i18nKey="ticketPurchased" />
                </h1>
              </Typography>
            </div>
            <Typography variant="paragraph" className="mt-2">
              <TransText
                i18nKey="bookingNo"
                values={{ number: successData.code }}
              />
            </Typography>
            <Divider className="mb-6 mt-4" />
            <Typography variant="paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
              auctor orci. Cras id iaculis velit. Donec sit amet nisl fringilla
              turpis ornare semper vel sit amet velit.
            </Typography>
          </div>

          <div className="mt-4 desktop:mt-0">
            <div className="flex flex-col gap-4 py-3 tablet:flex-row tablet:items-center">
              <Button
                fullWidth
                className="desktop:w-fit"
                data-testid="download-pdf-button"
                variant="tertiary"
                size="medium"
                onClick={handleTicketDownload}
                disabled={isDownloading || !successData.bookingId}
              >
                {isDownloading ? (
                  <Icons.loader className="h-4 w-4 animate-spin" />
                ) : (
                  <Icons.download height={16} width={16} />
                )}
                <TransText i18nKey="downloadPdf" />
              </Button>
              <Button
                fullWidth
                size="medium"
                className="desktop:w-fit"
                data-testid="download-pkpass-button"
                variant="tertiary"
                onClick={handlePkPassDownload}
                disabled={isPkPassDownloading || !successData.bookingId}
              >
                {isPkPassDownloading ? (
                  <Icons.loader className="h-4 w-4 animate-spin" />
                ) : (
                  <Icons.file height={16} width={16} />
                )}
                <TransText i18nKey="downloadPkpass" />
              </Button>
            </div>
            <Divider className="mb-6 mt-4" />
            <Typography variant="paragraph">
              <TransText i18nKey="ticketSent" />
            </Typography>
          </div>
        </div>
      </div>
    </Loadable>
  );
};

export default Success;
