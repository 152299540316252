import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { CardContent, CardPrimitiveRoot } from '@/components/primitives/Card';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useCallback } from 'react';
import { Form } from '@/components/primitives/Form';
import { Button } from '@/components/primitives/Button';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputField } from '@/components/form/InputField';
import { useDispatch, useSelector } from '@/store/utils';
import { toast } from 'react-toastify';
import { TransAlert } from '@/i18n/trans/alert';
import {
  createFindBookingSchema,
  type FindBookingValues,
} from '@/utils/zod/schema';
import { getManageFlowBookingByNumberAndEmail } from '@/features/manage/manageActions';
import { manageBookingLoadingSelector } from '@/features/loading/loadingSelectors';
import { Loadable } from '@/components/Loadable';
import i18nInstance, { NAMESPACE } from '@/i18n';

export const FindBooking = () => {
  const dispatch = useDispatch();
  const loading = useSelector(manageBookingLoadingSelector);
  const { t } = useTranslation(NAMESPACE, {
    i18n: i18nInstance,
  });
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const form = useForm<FindBookingValues>({
    reValidateMode: 'onBlur',
    resolver: zodResolver(createFindBookingSchema(t)),
    defaultValues: {
      email: '',
      bookingNumber: '',
    },
  });

  const onSubmit = useCallback(
    async (values: FindBookingValues) => {
      await dispatch(getManageFlowBookingByNumberAndEmail(values))
        .unwrap()
        .catch(() => {
          toast.warning(<TransAlert i18nKey="bookingsSearchFailed" />);
        });
    },
    [dispatch]
  );

  return (
    <Loadable loading={loading}>
      <div className="mx-5 mt-10 laptop:mx-auto laptop:max-w-[410px]">
        {/*TODO: Update it with h1 tag once the solution is implemented*/}
        <Typography
          variant={isLaptopOrBigger ? 'title' : 'mobile-title'}
          className="text-center laptop:text-left"
          asChild
        >
          <h1>
            <TransText i18nKey="manageBooking" />
          </h1>
        </Typography>

        <CardPrimitiveRoot className="mt-8 rounded-xl">
          <CardContent>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4 [&>*:last-child]:!mt-6"
              >
                <InputField
                  control={form.control}
                  name="email"
                  className="h-10"
                  required
                  placeholder={t('text.enterYourEmailAddress')}
                  label={<TransText i18nKey="emailUsedForBooking" />}
                />
                <InputField
                  control={form.control}
                  name="bookingNumber"
                  className="h-10"
                  required
                  placeholder={t('text.enterYourBookingNumber')}
                  label={<TransText i18nKey="bookingNumber" />}
                />
                <Button
                  size="medium"
                  fullWidth
                  className="rounded-lg"
                  data-testid="find-booking-btn"
                  type="submit"
                >
                  <TransText i18nKey="findYourBooking" />
                </Button>
              </form>
            </Form>
          </CardContent>
        </CardPrimitiveRoot>
      </div>
    </Loadable>
  );
};
