import { type FC, useMemo, useState } from 'react';
import type { ServiceAttributeDTO } from '@/types/dto';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/primitives/Tooltip';
import { Icons } from './index';
import { cn } from '@/utils/cn';
import { Typography } from '@/components/primitives/Typography';

export const ServiceAttributeIcon: FC<ServiceAttributeDTO> = ({
  code,
  description,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const iconName = useMemo(() => {
    switch (code) {
      case 'KOMAEK':
        return '1-class';
      case 'BOKEJ2':
        return '2-class-crossed';
      case 'KOMB':
      case 'KOM5':
      case 'KOMKLS':
        return '2-class';
      case 'KOMLUG':
        return '2-class-calm';
      case 'KOMEDJ':
        return 'pet-crossed';
      case 'KOMCYK':
        return 'bicycle';
      case 'KOMECY':
        return 'bicycle-crossed';
      case 'KOMRES':
      case 'KOMKRO':
        return 'restaurant';
      case 'KOMFRK':
      case 'KOMFRL':
        return 'breakfast-2-class';
      case 'KOMFRR':
      case 'KOMFRU':
        return 'breakfast-1-class';
      case 'KOM37':
      case 'KOMSER':
        return 'coffee-machine';
      case 'KOMKB1':
        return 'cafe-1-class';
      case 'KOMC':
        return 'couchette';
      case 'EVFEUB':
      case 'EVFEUJ':
        return 'eu-law';
      case 'EVFSFS':
        return 'sw-law';
      case 'KOMPLU':
        return 'food-1-class';
      case 'KOMIUK':
      case 'KOMINV':
        return 'wifi';
      case 'KOMBAG':
        return 'luggage';
      case 'BOKOBL':
        return 'reservation-mandatory';
      case 'KOMMAT':
        return 'food';
      case 'KOMMÅL':
        return 'food-plus';
      case 'KOM34':
        return 'bar';
      case 'KOMMOV':
        return 'movingo';
      case 'BOKEJB':
        return 'reservation-crossed';
      case 'KOMEJS':
        return 'coffee-machine-crossed';
      case 'KOMEAC':
        return 'ac-crossed';
      case 'KOMEWI':
        return 'wifi-crossed';
      case 'KOM74':
        return 'nonstop';
      case 'BOKFRI':
        return 'reservation-optional';
      case 'KOMEL':
        return 'power-socket';
      case 'KOMFUN':
      case 'KOMTAF':
        return 'breakfast';
      case 'KOMLYF':
        return 'wheelchair-ramp';
      default:
        return '';
    }
  }, [code]);

  if (!iconName) return null;

  const IconComponent = Icons[iconName as keyof typeof Icons];

  if (!IconComponent) return null;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    switch (event.key) {
      case ' ':
      case 'Enter':
        event.preventDefault();
        setIsTooltipOpen((prev) => !prev);
        break;
      case 'Escape':
        setIsTooltipOpen(false);
        break;
    }
  };

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
        <TooltipTrigger asChild>
          <span
            role="img"
            aria-label={description}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            className={cn(
              'inline-block',
              'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-tint focus-visible:ring-offset-2'
            )}
          >
            <IconComponent
              width={['sw-law', 'eu-law'].includes(iconName) ? 16 : 12}
              height={['sw-law', 'eu-law'].includes(iconName) ? 16 : 12}
              aria-hidden="true"
            />
          </span>
        </TooltipTrigger>
        <TooltipContent>
          <Typography variant="body2">{description}</Typography>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
