import type { LoginValues } from '@/utils/zod/schema';
import getApiInstance from '@/api';

export const login = async (values: LoginValues) => {
  const api = (await getApiInstance()).customerProfileApi;

  await api.Login_Login(
    null,
    {
      grant_type: 'password',
      username: values.email,
      password: values.password,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};
