import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/utils/cn';
import { typographyVariants } from '@/components/primitives/Typography';

const badgeVariants = cva(
  cn(
    'inline-flex items-center gap-1 rounded-full border px-2 py-1 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-tint focus:ring-offset-2',
    typographyVariants({ variant: 'label' })
  ),
  {
    variants: {
      variant: {
        success: 'border-success-border bg-success-surface text-success-text',
        info: 'border-primary bg-primary-light text-primary',
        attention: 'border-warning-border bg-warning-surface text-dark',
        negative: 'border-error-border bg-error-surface text-error-text',
        light: 'border-none bg-primary-light text-dark',
        disabled: 'border-neutral-light bg-neutral-pale text-neutral',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(badgeVariants({ variant }), className)}
        {...props}
      />
    );
  }
);

Badge.displayName = 'Badge';

export { Badge, badgeVariants };
