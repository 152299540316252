import type { TransKeys } from '@/i18n/trans/index';
import { createTrans } from '@/i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransText = createTrans({
  accountCreated: (
    <Trans i18nKey="text.accountCreated" defaults="Account created!" />
  ),
  activation: <Trans i18nKey="text.activation" defaults="Activation" />,
  addNewAncillary: (
    <Trans i18nKey="text.addNewancillary" defaults="Add new ancillary" />
  ),
  addNewAncillaryModal: (
    <Trans
      i18nKey="text.addNewAncillaryModal"
      defaults="Add new ancillary modal"
    />
  ),
  ancillaries: <Trans i18nKey="text.ancillaries" defaults="Ancillaries" />,
  ancillaryPricePerPassenger: (
    <Trans
      i18nKey="text.ancillaryPricePerPassenger"
      defaults="Ancillary price per passenger"
    />
  ),
  areYouSureYouWantToCancelThePurchase: (
    <Trans
      i18nKey="text.areYouSureYouWantToCancelThePurchase"
      defaults="Are you sure you want to cancel the purchase?"
    />
  ),
  areYouSureYouWantToCancelYourBooking: (
    <Trans
      i18nKey="text.areYouSureYouWantToCancelYourBooking"
      defaults="Are you sure you want to cancel your booking?"
    />
  ),
  arrival: <Trans i18nKey="text.arrival" defaults="Arrival" />,
  back: <Trans i18nKey="text.back" defaults="Back" />,
  backToJourneySearch: (
    <Trans
      i18nKey="text.backToJourneySearch"
      defaults="Back to the journey search"
    />
  ),
  banks: <Trans i18nKey="text.banks" defaults="Banks" />,
  bookedTravelPasses: (
    <Trans i18nKey="text.bookedTravelPasses" defaults="Booked travel passes" />
  ),
  booking: <Trans i18nKey="text.booking" defaults="Booking" />,
  bookingItems: <Trans i18nKey="text.bookingItems" defaults="Booking items" />,
  bookingNo: (
    <Trans
      i18nKey="text.bookingNo"
      defaults="Your booking number is: {{number}}"
    />
  ),
  bookingNumber: (
    <Trans i18nKey="text.bookingNumber" defaults="Booking number" />
  ),
  bookingSessionExpired: (
    <Trans
      i18nKey="text.bookingSessionExpired"
      defaults="Booking session expired"
    />
  ),
  bookingSessionExpiresIn: (
    <Trans
      i18nKey="text.bookingSessionExpiresIn"
      defaults="Booking session expires in"
    />
  ),
  bookingSessionExpiryDescription: (
    <Trans
      i18nKey="text.bookingSessionExpiryDescription"
      defaults="Your booking session has expired. Start a new session to resume the booking process."
    />
  ),
  bookingSummary: (
    <Trans i18nKey="text.bookingSummary" defaults="Booking summary" />
  ),
  bookingTotal: <Trans i18nKey="text.bookingTotal" defaults="Booking total" />,
  bus: <Trans i18nKey="text.bus" defaults="Bus" />,
  cancel: <Trans i18nKey="text.cancel" defaults="Cancel" />,
  cancelPurchase: (
    <Trans i18nKey="text.cancelPurchase" defaults="Cancel purchase" />
  ),
  changeSearchInputs: (
    <Trans
      i18nKey="text.changeSearchInputs"
      defaults="No trips found. Please change the search inputs and try again."
    />
  ),
  changeable: <Trans i18nKey="text.changeable" defaults="Changeable" />,
  checkYourEmail: (
    <Trans i18nKey="text.checkYourEmail" defaults="Check your email" />
  ),
  checkout: <Trans i18nKey="text.checkout" defaults="Checkout" />,
  chooseAncillaryAndConfirmChoice: (
    <Trans
      i18nKey="text.chooseAncillaryAndConfirmChoice"
      defaults="Choose ancillary and confirm the choice"
    />
  ),
  chooseTripsToRefund: (
    <Trans
      i18nKey="text.chooseTripsToRefund"
      defaults="Choose trip(s) to refund"
    />
  ),
  clickHereToSearch: (
    <Trans i18nKey="text.clickHereToSearch" defaults="Click here to search" />
  ),
  close: <Trans i18nKey="text.close" defaults="Close" />,
  confirm: <Trans i18nKey="text.confirm" defaults="Confirm" />,
  confirmRegistration: (
    <Trans i18nKey="text.confirmRegistration" defaults="Confirm registration" />
  ),
  confirmRegistrationDescription: (
    <Trans
      i18nKey="text.confirmRegistrationDescription"
      defaults="We have sent you a verification email. To continue, please click on the verification link provided in that email. If you haven’t received it, please check your spam folder or click on the resend link below."
    />
  ),
  continue: <Trans i18nKey="text.continue" defaults="Continue" />,
  continueWithEmail: (
    <Trans i18nKey="text.continueWithEmail" defaults="Continue with email" />
  ),
  createAnAccount: (
    <Trans i18nKey="text.createAnAccount" defaults="Create an account" />
  ),
  createPassword: (
    <Trans i18nKey="text.createPassword" defaults="Create password" />
  ),
  custom: <Trans i18nKey="text.custom" defaults="Custom" />,
  departure: <Trans i18nKey="text.departure" defaults="Departure" />,
  details: <Trans i18nKey="text.details" defaults="Details" />,
  direct: <Trans i18nKey="text.direct" defaults="Direct" />,
  dismiss: <Trans i18nKey="text.dismiss" defaults="Dismiss" />,
  dismissWarning: (
    <Trans i18nKey="text.dismissWarning" defaults="Dismiss warning" />
  ),
  downloadPdf: <Trans i18nKey="text.downloadPdf" defaults="Download pdf" />,
  downloadPkpass: (
    <Trans i18nKey="text.downloadPkpass" defaults="Download pkpass" />
  ),
  editSearch: <Trans i18nKey="text.editSearch" defaults="Edit search" />,
  email: <Trans i18nKey="text.email" defaults="Email" />,
  emailUsedForBooking: (
    <Trans
      i18nKey="text.emailUsedForBooking"
      defaults="Email (used for booking)"
    />
  ),
  enterNameEmailAndPhoneNumber: (
    <Trans
      i18nKey="text.enterNameEmailAndPhoneNumber"
      defaults="Enter name, email and phone number"
    />
  ),
  enterYourBookingNumber: (
    <Trans
      i18nKey="text.enterYourBookingNumber"
      defaults="Enter your booking number"
    />
  ),
  enterYourEmailAddress: (
    <Trans
      i18nKey="text.enterYourEmailAddress"
      defaults="Enter your email address"
    />
  ),
  enterYourPassword: (
    <Trans i18nKey="text.enterYourPassword" defaults="Enter your password" />
  ),
  errorDownloadingPkpass: (
    <Trans
      i18nKey="text.errorDownloadingPkpass"
      defaults="Failed to download pkpass"
    />
  ),
  errorSendingEmail: (
    <Trans
      i18nKey="text.errorSendingEmail"
      defaults="Confirmation email not sent"
    />
  ),
  failedToCreateAccount: (
    <Trans
      i18nKey="text.failedToCreateAccount"
      defaults="Failed to create account."
    />
  ),
  fareDetails: <Trans i18nKey="text.fareDetails" defaults="Fare details" />,
  fillOutFollowingRegistrationForm: (
    <Trans
      i18nKey="text.fillOutFollowingRegistrationForm"
      defaults="Please fill out the following registration form to sign up with us."
    />
  ),
  findYourBooking: (
    <Trans i18nKey="text.findYourBooking" defaults="Find your booking" />
  ),
  firstName: <Trans i18nKey="text.firstName" defaults="First name" />,
  forgotPassword: (
    <Trans i18nKey="text.forgotPassword" defaults="Forgot password?" />
  ),
  from: <Trans i18nKey="text.from" defaults="From" />,
  gladToHaveYouOnBoard: (
    <Trans
      i18nKey="text.gladToHaveYouOnBoard"
      defaults="Glad to have you on board!"
    />
  ),
  goBack: <Trans i18nKey="text.goBack" defaults="Go back" />,
  hello: <Trans i18nKey="text.hello" defaults="Hello!" />,
  iAgreeWithTermsAndConditions: (
    <Trans
      i18nKey="text.iAgreeWithTermsAndConditions"
      defaults="I agree with <anchor>terms & conditions</anchor>"
    />
  ),
  inbound: <Trans i18nKey="text.inbound" defaults="Inbound" />,
  inboundTrip: <Trans i18nKey="text.inboundTrip" defaults="Inbound trip" />,
  inboundTrips: (
    <Trans
      i18nKey="text.inboundTrips"
      defaults="(1)[Inbound trip];(2-inf)[Inbound trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  journeyTransfersCount: (
    <Trans
      i18nKey="text.journeyTransfersCount"
      defaults="(0)[{{count}} transfers];(1)[{{count}} transfer];(2-inf)[{{count}} transfers];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  lastName: <Trans i18nKey="text.lastName" defaults="Last name" />,
  legNotSelected: (
    <Trans i18nKey="text.legNotSelected" defaults="Leg not selected" />
  ),
  logIn: <Trans i18nKey="text.logIn" defaults="Log in" />,
  loginFailed: <Trans i18nKey="text.loginFailed" defaults="Login failed" />,
  manageBooking: (
    <Trans i18nKey="text.manageBooking" defaults="Manage booking" />
  ),
  minutesCount: (
    <Trans
      i18nkey="text.minutesCount"
      defaults="(0)[minutes];(1)[minute];(2-inf)[minutes];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  nextAvailableDate: (
    <Trans
      i18nKey="text.nextAvailableDate"
      defaults="Next available date: {{date}}"
    />
  ),
  noAncillariesSelected: (
    <Trans
      i18nKey="text.noAncillariesChosen"
      defaults="No ancillaries selected"
    />
  ),
  noCountryFound: (
    <Trans i18nKey="text.noCountryFound" defaults="No country found" />
  ),
  noTravelPassesFound: (
    <Trans
      i18nKey="text.noTravelPassesFound"
      defaults="No travel passes found"
    />
  ),
  noTripsFoundNextDate: (
    <Trans
      i18nKey="text.noTripsFoundNextDate"
      defaults="No trips found. Choose a different date or change the search inputs"
    />
  ),
  nonRefundable: (
    <Trans i18nKey="text.nonRefundable" defaults="Non-refundable" />
  ),
  notAvailable: <Trans i18nKey="text.notAvailable" defaults="Not available" />,
  notRefundable: (
    <Trans i18nKey="text.notRefundable" defaults="Non-refundable" />
  ),
  notRegisteredYet: (
    <Trans i18nKey="text.notRegisteredYet" defaults="Not registered yet?" />
  ),
  numberOfYearsOld: (
    <Trans
      i18nKey="text.numberOfYearsOld"
      defaults="(0-1)[{{count}} year old];(2-inf)[{{count}} years old];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  optional: <Trans i18nKey="text.optional" defaults="optional" />,
  orContinueWith: (
    <Trans i18nKey="text.orContinueWith" defaults="or continue with" />
  ),
  orLogInWith: <Trans i18nKey="text.orLogInWith" defaults="or log in with" />,
  other: <Trans i18nKey="text.other" defaults="Other" />,
  outbound: <Trans i18nKey="text.outbound" defaults="Outbound" />,
  outboundTrip: <Trans i18nKey="text.outboundTrip" defaults="Outbound trip" />,
  outboundTrips: (
    <Trans
      i18nKey="text.outboundTrips"
      defaults="(0)[Outbound trips];(1)[Outbound trip];(2-inf)[Outbound trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  outline: <Trans i18nKey="text.outline" defaults="Outline" />,
  partiallyRefunded: (
    <Trans i18nKey="text.partiallyRefunded" defaults="Partially refunded" />
  ),
  passengerCount: (
    <Trans
      i18nKey="text.passengerCount"
      defaults="(0)[{{count}} passengers];(1)[{{count}} passenger];(2-inf)[{{count}} passengers]"
    />
  ),
  passengerIndex: (
    <Trans
      i18nKey="text.passengerIndex"
      defaults="(0)[Passenger];(1-inf)[Passenger {{count}}];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  passengers: <Trans i18nKey="text.passengers" defaults="Passengers" />,
  passengersCount: (
    <Trans
      i18nKey="text.passengersCount"
      defaults="(0)[{{count}} passengers];(1)[{{count}} passenger];(2-inf)[{{count}} passengers];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  password: <Trans i18nKey="text.password" defaults="Password" />,
  pay: <Trans i18nKey="text.pay" defaults="Pay" />,
  payer: <Trans i18nKey="text.payer" defaults="Payer" />,
  paymentMethod: (
    <Trans i18nKey="text.paymentMethod" defaults="Payment method" />
  ),
  phoneNumber: <Trans i18nKey="text.phoneNumber" defaults="Phone number" />,
  priceIsForAllPassengers: (
    <Trans
      i18nKey="text.priceIsForAllPassengers"
      defaults="The price covers all passengers."
    />
  ),
  pricePerPassenger: (
    <Trans i18nKey="text.pricePerPassenger" defaults="Price per passenger " />
  ),
  purchase: <Trans i18nKey="text.purchase" defaults="Purchase" />,
  readMore: <Trans i18nKey="text.readMore" defaults="Read more" />,
  refund: <Trans i18nKey="text.refund" defaults="Refund" />,
  refundFee: <Trans i18nKey="text.refundFee" defaults="Refund fee" />,
  refundInitiated: (
    <Trans
      i18nKey="text.refundInitiated"
      defaults="Trip successfully cancelled. Your refund will be issued in 3 business days."
    />
  ),
  refundIssuedText: (
    <Trans
      i18nKey="text.refundIssuedText"
      defaults="The refund will be issued to the card that was used to pay for the booking."
    />
  ),
  refundModalDescription: (
    <Trans
      i18nKey="text.refundModalDescription"
      defaults="Modal for refunding tickets and admissions"
    />
  ),
  refundTrips: (
    <Trans
      i18nKey="text.refundTrips"
      defaults="(1)[Refund trip];(2-inf)[Refund trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  refundable: <Trans i18nKey="text.refundable" defaults="Refundable" />,
  refunded: <Trans i18nKey="text.refunded" defaults="Refunded" />,
  remainingUses: (
    <Trans i18nKey="text.remainingUses" defaults="Remaining uses" />
  ),
  remove: <Trans i18nKey="text.remove" defaults="Remove" />,
  repeatPassword: (
    <Trans i18nKey="text.repeatPassword" defaults="Repeat password" />
  ),
  resendConfirmationEmail: (
    <Trans
      i18nKey="text.resendConfirmationEmail"
      defaults="Resend confirmation email"
    />
  ),
  returnTrip: <Trans i18nKey="text.returnTrip" defaults="Return trip" />,
  returnTrips: (
    <Trans
      i18nKey="text.returnTrips"
      defaults="(1)[Return trip];(2-inf)[Return trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  searchCountry: (
    <Trans i18nKey="text.searchCountry" defaults="Search country" />
  ),
  secondsCount: (
    <Trans
      i18nkey="text.secondsCount"
      defaults="(0)[seconds];(1)[second];(2-inf)[seconds];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  select: <Trans i18nKey="text.select" defaults="Select" />,
  selectAncillary: (
    <Trans i18nKey="text.selectAncillary" defaults="Select ancillary" />
  ),
  selectLanguage: (
    <Trans i18nKey="text.selectLanguage" defaults="Select language" />
  ),
  selectLeg: <Trans i18nKey="text.selectLeg" defaults="Select leg" />,
  selectLegToContinue: (
    <Trans
      i18nKey="text.selectLegToContinue"
      defaults="Select leg to continue"
    />
  ),
  selectOutboundFare: (
    <Trans i18nKey="text.selectOutboundFare" defaults="Select outbound fare" />
  ),
  selectOutboundTrip: (
    <Trans i18nKey="text.selectOutboundTrip" defaults="Select outbound trip" />
  ),
  selectPassengers: (
    <Trans i18nKey="text.selectPassengers" defaults="Select passengers" />
  ),
  selectPassengersToRefund: (
    <Trans
      i18nKey="text.selectPassengersToRefund"
      defaults="Select passengers to refund"
    />
  ),
  selectReturnFare: (
    <Trans i18nKey="text.selectReturnFare" defaults="Select return fare" />
  ),
  selectReturnTrip: (
    <Trans i18nKey="text.selectReturnTrip" defaults="Select return trip" />
  ),
  selectTravelPass: (
    <Trans i18nKey="text.selectTravelPass" defaults="Select travel pass" />
  ),
  selected: <Trans i18nKey="text.selected" defaults="Selected" />,
  sessionAboutToExpire: (
    <Trans
      i18nKey="text.sessionAboutToExpire"
      defaults="Session about to expire"
    />
  ),
  showLess: <Trans i18nKey="text.showLess" defaults="Show less" />,
  showMore: <Trans i18nKey="text.showMore" defaults="Show more" />,
  stopsCount: (
    <Trans
      i18nKey="text.stops"
      defaults="(0)[{{count}} stops];(1)[{{count}} stop];(2-inf)[{{count}} stops];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  ticketPurchased: (
    <Trans i18nKey="text.ticketPurchased" defaults="Tickets purchased" />
  ),
  ticketSent: (
    <Trans
      i18nKey="text.ticketSent"
      defaults="Your tickets have been sent to your email address."
    />
  ),
  total: <Trans i18nKey="text.total" defaults="Total" />,
  totalPrice: <Trans i18nKey="text.totalPrice" defaults="Total price" />,
  totalRefund: <Trans i18nKey="text.totalRefund" defaults="Total refund" />,
  train: <Trans i18nKey="text.train" defaults="Train" />,
  transfer: <Trans i18nKey="text.transfer" defaults="Transfer" />,
  transferTime: <Trans i18nKey="text.transferTime" defaults="transfer time" />,
  travelPass: <Trans i18nKey="text.travelPass" defaults="Travel pass" />,
  travelPassDetails: (
    <Trans i18nKey="text.travelPassDetails" defaults="Travel pass details" />
  ),
  tripLegPartIndex: (
    <Trans i18nKey="text.tripLegPartIndex" defaults="Part {{tripLegPart}}" />
  ),
  unchangeable: <Trans i18nKey="text.unchangeable" defaults="Unchangeable" />,
  undefinedConsentDescription: (
    <Trans
      i18nKey="text.undefinedConsentDescription"
      defaults="Undefined consent description"
    />
  ),
  validFrom: <Trans i18nKey="text.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="text.validTo" defaults="Valid to" />,
  yearsOld: (
    <Trans
      i18nKey="text.yearsOld"
      defaults="(0)[{{age}} years];(1)[{{age}} year];(2-inf)[{{age}} years];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  youAreAboutToRefundFollowingItems: (
    <Trans
      i18nKey="text.youAreAboutToRefundFollowingItems"
      defaults="You are about to refund the following items:"
    />
  ),
});

export type TransTextKeys = TransKeys<typeof TransText>;
