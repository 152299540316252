import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from '@/store/utils';
import { Typography } from '@/components/primitives/Typography';
import { Form } from '@/components/primitives/Form';
import LegOptionsSection from '@/components/purchase/checkout/ancillaries/ancillary-modal/LegOptionsSection';
import AncillaryOptions from '@/components/purchase/checkout/ancillaries/ancillary-modal/AncillaryOptions';
import { MobileAncillaryModal } from '@/components/purchase/checkout/ancillaries/ancillary-modal/mobile-ancillary-modal/MobileAncillaryModal';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import RegularAncillaryModal from '@/components/purchase/checkout/ancillaries/ancillary-modal/regular-ancillary-modal/RegularAncillaryModal';
import {
  createAncillarySchema,
  type AncillaryValues,
  type PassengerValues,
} from '@/utils/zod/schema';
import { TransText } from '@/i18n/trans/text';
import { zodResolver } from '@hookform/resolvers/zod';
import { purchaseFlowBookingIdSelector } from '@/features/purchase/purchaseSelectors';
import {
  addPurchaseFlowBookingAncillary,
  getPurchaseFlowBookingById,
} from '@/features/purchase/purchaseActions';
import { useTranslation } from 'react-i18next';
import { TransAlert } from '@/i18n/trans/alert';
import { toast } from 'react-toastify';
import RegularAncillaryModalFooter from '@/components/purchase/checkout/ancillaries/ancillary-modal/regular-ancillary-modal/RegularAncillaryModalFooter';
import MobileAncillaryModalFooter from '@/components/purchase/checkout/ancillaries/ancillary-modal/mobile-ancillary-modal/MobileAncillaryModalFooter';
import i18nInstance, { NAMESPACE } from '@/i18n';

interface AncillaryModalProps {
  passengerListForm: UseFormReturn<{
    passengers: Array<PassengerValues>;
  }>;
}

const AncillaryModal: FC<AncillaryModalProps> = ({ passengerListForm }) => {
  const dispatch = useDispatch();
  const bookingId = useSelector(purchaseFlowBookingIdSelector);
  const { t } = useTranslation(NAMESPACE, {
    i18n: i18nInstance,
  });

  const form = useForm<AncillaryValues>({
    reValidateMode: 'onBlur',
    resolver: zodResolver(createAncillarySchema(t)),
    defaultValues: {
      legId: '',
      selectedAncillaries: [],
    },
  });
  const { reset, watch, handleSubmit } = form;
  const selectedAncillaries = watch('selectedAncillaries');
  const selectedLegId = watch('legId');

  const [isOverlayOpened, setIsOverlayOpened] = useState(false);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  useEffect(() => {
    if (!isOverlayOpened) {
      reset({
        legId: '',
        selectedAncillaries: [],
      });
    }
  }, [isOverlayOpened, reset]);

  const onSubmit = useCallback(
    async (values: AncillaryValues) => {
      // TODO: Implement proper form validations
      const validInformation = await form.trigger();

      if (validInformation) {
        await dispatch(addPurchaseFlowBookingAncillary(values))
          .unwrap()
          .then((results) => {
            if (results) {
              // TODO: Result headers contain ids of ancillaries that werent added, show error(s)
            }

            dispatch(getPurchaseFlowBookingById(bookingId))
              .unwrap()
              .then(() => {
                setIsOverlayOpened(false);
              });
          });
      }
    },
    [bookingId, dispatch, form]
  );

  const getSelectedAncillariesTotalPrice = useCallback(
    () =>
      selectedAncillaries.reduce(
        (sum, ancillary) =>
          sum +
          (ancillary?.amount || 0) *
            (ancillary?.passengersExternalReferences?.length || 0),
        0
      ),
    [selectedAncillaries]
  );

  const [Component, Footer] = isLaptopOrBigger
    ? [RegularAncillaryModal, RegularAncillaryModalFooter]
    : [MobileAncillaryModal, MobileAncillaryModalFooter];

  return (
    <Component
      isOverlayOpened={isOverlayOpened}
      setIsOverlayOpened={setIsOverlayOpened}
      totalPrice={getSelectedAncillariesTotalPrice()}
    >
      <Form {...form}>
        <form
          onSubmit={handleSubmit(onSubmit, () =>
            toast.error(<TransAlert i18nKey="ancillaryInfoMissing" />)
          )}
          className="h-full"
        >
          <div className="relative grid min-w-mobile grid-cols-1 grid-rows-[auto,auto,1fr] gap-4 px-6 pb-6 pt-4 laptop:min-w-[800px] laptop:grid-cols-2 laptop:flex-row laptop:gap-10">
            <div className="flex w-full gap-2">
              <div className="flex w-full flex-col gap-2">
                <Typography variant="subtitle">
                  1. <TransText i18nKey="selectLeg" />
                </Typography>
                <LegOptionsSection />
              </div>
            </div>
            {selectedLegId ? (
              <div className="flex flex-col justify-between gap-2">
                <div className="flex justify-between">
                  <Typography variant="subtitle">
                    2. <TransText i18nKey="selectAncillary" />
                  </Typography>
                  <Typography variant="body2" className="text-neutral">
                    (<TransText i18nKey="pricePerPassenger" />)
                  </Typography>
                </div>
                <AncillaryOptions passengerListForm={passengerListForm} />
              </div>
            ) : (
              <Typography variant="body1" className="text-neutral">
                <TransText i18nKey="selectLegToContinue" />
              </Typography>
            )}
          </div>
          <Footer
            setIsOverlayOpened={setIsOverlayOpened}
            totalPrice={getSelectedAncillariesTotalPrice()}
          />
        </form>
      </Form>
    </Component>
  );
};

export default AncillaryModal;
