import type { FC } from 'react';
import i18nInstance, {
  getLanguage,
  localeConfigurations,
  NAMESPACE,
} from '@/i18n';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import _toUpper from 'lodash/toUpper';
import { useTranslation } from 'react-i18next';
import { PopoverItem } from '@/components/primitives/Popover';

interface LanguageSelectContentProps {}

const LanguageSelectContent: FC<LanguageSelectContentProps> = () => {
  const {
    i18n: { changeLanguage },
  } = useTranslation(NAMESPACE, {
    i18n: i18nInstance,
  });
  const currentLanguage = getLanguage();

  return Object.entries(localeConfigurations).map(([code, config], key) => (
    <PopoverItem
      key={key}
      active={code === currentLanguage}
      onClick={async () => {
        await changeLanguage(code);
        window.location.reload();
      }}
      data-testid={`language-select-choose-${code}`}
    >
      <Typography variant="body1">{config.label}</Typography>
      <div className="flex items-center gap-2">
        {code === currentLanguage && (
          <Icons.checkCircle
            height={16}
            width={16}
            className="text-success-text"
          />
        )}
        {_toUpper(code)}
      </div>
    </PopoverItem>
  ));
};

export default LanguageSelectContent;
