import { type FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { createLoginSchema, type LoginValues } from '@/utils/zod/schema';
import { Loadable } from '@/components/Loadable';
import { Typography } from '@/components/primitives/Typography';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { TransText } from '@/i18n/trans/text';
import { CardContent, CardPrimitiveRoot } from '@/components/primitives/Card';
import { Form } from '@/components/primitives/Form';
import { InputField } from '@/components/form/InputField';
import { Icons } from '@/components/icons';
import { Button } from '@/components/primitives/Button';
import Divider from '@/components/primitives/Divider';
import { login } from '@/features/login/loginService';
import { toast } from 'react-toastify';
import i18nInstance, { NAMESPACE } from '@/i18n';
import { useNavigate } from 'react-router-dom';
import PasswordField from '@/components/PasswordField';

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const { t } = useTranslation(NAMESPACE, {
    i18n: i18nInstance,
  });
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const [loading, setLoading] = useState(false);

  const form = useForm<LoginValues>({
    reValidateMode: 'onBlur',
    resolver: zodResolver(createLoginSchema(t)),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const navigate = useNavigate();

  const onSubmit = useCallback(async (values: LoginValues) => {
    setLoading(true);
    login(values)
      .then(() => {
        // store the token
      })
      .catch((e) => {
        toast.error(
          <Typography variant="body1">
            <TransText i18nKey="loginFailed" />.{' '}
            {e.response.data?.error_description}
          </Typography>
        );
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Loadable loading={loading} overlay>
      <div className="mx-5 mt-10 laptop:mx-auto laptop:max-w-[410px]">
        <Typography
          variant={isLaptopOrBigger ? 'title' : 'mobile-title'}
          className="text-center laptop:text-left"
          asChild
        >
          <h1>
            <TransText i18nKey="logIn" />
          </h1>
        </Typography>
        <CardPrimitiveRoot className="mt-8 rounded-xl">
          <CardContent className="flex flex-col gap-6">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col gap-3"
              >
                <InputField
                  control={form.control}
                  name="email"
                  className="h-10"
                  required
                  placeholder={t('text.enterYourEmailAddress')}
                  label={<TransText i18nKey="email" />}
                />
                <PasswordField
                  name="password"
                  control={form.control}
                  label={<TransText i18nKey="password" />}
                />
                <Button
                  data-testid="login-submit-button"
                  type="submit"
                  className="mt-3 w-full"
                >
                  <Typography variant="button">
                    <TransText i18nKey="logIn" />
                  </Typography>
                </Button>
              </form>
            </Form>
            <Button
              data-testid="forgot-password-button"
              onClick={() => console.log('Forgot password?')}
              variant="ghost"
              className="self-center"
            >
              <Typography variant="button">
                <TransText i18nKey="forgotPassword" />
              </Typography>
            </Button>
            <div className="flex w-full items-center gap-4 px-4">
              <Divider />
              <Typography variant="body1" className="flex-shrink-0">
                <TransText i18nKey="orLogInWith" />
              </Typography>
              <Divider />
            </div>
            <div className="flex items-center justify-center gap-4">
              <Button
                variant="icon"
                data-testid="facebook-login-button"
                className="h-fit p-2"
              >
                <Icons.facebook />
              </Button>
              <Button
                variant="icon"
                data-testid="google-login-button"
                className="h-fit p-2"
              >
                <Icons.google />
              </Button>
              <Button
                variant="icon"
                data-testid="apple-login-button"
                className="h-fit p-2"
              >
                <Icons.apple />
              </Button>
            </div>
          </CardContent>
        </CardPrimitiveRoot>
        <div className="mt-4 flex items-center justify-center gap-3">
          <Typography variant="body1">
            <TransText i18nKey="notRegisteredYet" />
          </Typography>
          <Button
            variant="ghost"
            data-testid="create-an-account-button"
            className="px-0 text-accent"
            onClick={() => navigate('/signup')}
          >
            <Typography variant="button">
              <TransText i18nKey="createAnAccount" />
            </Typography>
          </Button>
        </div>
      </div>
    </Loadable>
  );
};

export default Login;
