import type { AdmissionDTO } from '@/types/dto';
import { useMemo } from 'react';

export const useBookingAdmissionsStatuses = (
  admissions: Array<AdmissionDTO>
) => {
  const areAllRefunded = useMemo(
    () => admissions.every((admission) => admission.status === 'REFUNDED'),
    [admissions]
  );

  const isPartiallyRefunded = useMemo(
    () => admissions.some((admission) => admission.status === 'REFUNDED'),
    [admissions]
  );

  const areAllExchanged = useMemo(
    () => admissions.every((admission) => admission.status === 'EXCHANGED'),
    [admissions]
  );

  const areAllRefundable = useMemo(
    () =>
      !areAllRefunded &&
      !areAllExchanged &&
      admissions.every((admission) => admission.refundable === 'YES'),
    [admissions, areAllExchanged, areAllRefunded]
  );

  const areAllExchangeable = useMemo(
    () =>
      !areAllRefunded &&
      !areAllExchanged &&
      admissions.every((admission) => admission.exchangeable === 'YES'),
    [admissions, areAllExchanged, areAllRefunded]
  );

  return {
    areAllRefunded,
    isPartiallyRefunded,
    areAllExchanged,
    areAllRefundable,
    areAllExchangeable,
  };
};
