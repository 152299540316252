import type { TFunction } from 'i18next';

const validationMessage = (t: TFunction) => ({
  mustBeFilled: { message: t('validation.mustBeFilled', 'Must be filled') },
  invalidEmailFormat: {
    message: t('validation.invalidEmail', 'Invalid email format'),
  },
  invalidPaymentMethod: {
    message: t('validation.invalidPaymentMethod', 'Invalid payment method'),
  },
  mustAgreeBeforeContinuing: {
    message: t(
      'validation.mustAgreeBeforeContinuing',
      'You must agree before continuing'
    ),
  },
  minimumOne: {
    message: t('validation.minimumOne', 'Must choose at least one'),
  },
  minimumEight: {
    message: t('validation.minimumEight', 'Must be at least 8 characters long'),
  },
  maximum64: {
    message: t(
      'validation.maximum64',
      'Can not be more than 64 characters long'
    ),
  },
  mustChooseLeg: {
    message: t('validation.mustChooseLeg', 'You must choose a leg'),
  },
  passwordsMustMatch: {
    message: t('validation.passwordsMustMatch', 'Passwords must match'),
  },
});

export default validationMessage;
