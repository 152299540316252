import { type FC, useCallback } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { CardContent, CardPrimitiveRoot } from '@/components/primitives/Card';
import { Form } from '@/components/primitives/Form';
import { InputField } from '@/components/form/InputField';
import { Button } from '@/components/primitives/Button';
import Divider from '@/components/primitives/Divider';
import { Icons } from '@/components/icons';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useTranslation } from 'react-i18next';
import i18nInstance, { NAMESPACE } from '@/i18n';
import { useForm } from 'react-hook-form';
import { createSignupSchema, type SignupValues } from '@/utils/zod/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import PasswordField from '@/components/PasswordField';
import Consents from '@/components/purchase/checkout/consents/Consents';
import { toast } from 'react-toastify';
import type { CreateCustomerProfileRequestBodyDTO } from '@/types/dto';
import { createCustomerProfile } from '@/features/signup/signupService';
import type { ApiErrorResponse } from '@/types/api';
import { useNavigate } from 'react-router-dom';

interface SignupFormProps {
  email: CreateCustomerProfileRequestBodyDTO['email'];
}

const SignupForm: FC<SignupFormProps> = ({ email }) => {
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const { t } = useTranslation(NAMESPACE, { i18n: i18nInstance });
  const navigate = useNavigate();
  const form = useForm<SignupValues>({
    reValidateMode: 'onSubmit',
    resolver: zodResolver(createSignupSchema(t)),
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      repeatPassword: '',
      consents: [],
    },
  });

  const onSubmit = useCallback(
    (values: SignupValues) => {
      createCustomerProfile({ ...values, email })
        .then((id) => {
          toast.success(<TransText i18nKey="accountCreated" />);
          navigate('/signup/confirm', { state: { id } });
        })
        .catch((e: ApiErrorResponse) => {
          const reason = e.response?.data.errors?.[0]?.message;
          toast.error(
            <div className="flex gap-1">
              <TransText i18nKey="failedToCreateAccount" />
              <Typography>{reason}</Typography>
            </div>
          );
        });
    },
    [email, navigate]
  );

  return (
    <div className="mx-5 mt-10 laptop:mx-auto laptop:max-w-[410px]">
      <Typography
        variant={isLaptopOrBigger ? 'title' : 'mobile-title'}
        className="text-center laptop:text-left"
        asChild
      >
        <h1>
          <TransText i18nKey="hello" />
        </h1>
      </Typography>
      <CardPrimitiveRoot className="mt-8 rounded-xl">
        <CardContent>
          <Typography variant={isLaptopOrBigger ? 'heading2' : 'subtitle'}>
            <TransText i18nKey="gladToHaveYouOnBoard" />
          </Typography>
          <Typography variant="body1" className="py-3">
            <TransText i18nKey="fillOutFollowingRegistrationForm" />
          </Typography>
          <Divider grow className="mb-6" />

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-3"
            >
              <InputField
                name="firstName"
                control={form.control}
                label={<TransText i18nKey="firstName" />}
              />
              <InputField
                name="lastName"
                control={form.control}
                label={<TransText i18nKey="lastName" />}
              />
              <InputField
                name="phoneNumber"
                control={form.control}
                label={
                  <Typography variant="body2" className="text-neutral">
                    <TransText i18nKey="phoneNumber" /> (
                    <TransText i18nKey="optional" />)
                  </Typography>
                }
                type="number"
                className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              />
              <Divider grow className="my-3" />
              <PasswordField
                name="password"
                control={form.control}
                label={<TransText i18nKey="createPassword" />}
              />
              <PasswordField
                name="repeatPassword"
                control={form.control}
                label={<TransText i18nKey="repeatPassword" />}
              />
              <Divider grow className="my-3" />
              <div className="mb-3">
                <Consents />
              </div>
              <Button
                data-testid="signup-form-confirm-button"
                className="flex w-full gap-1.5"
              >
                <Icons.check />
                <Typography variant="button">
                  <TransText i18nKey="confirm" />
                </Typography>
              </Button>
            </form>
          </Form>
        </CardContent>
      </CardPrimitiveRoot>
    </div>
  );
};

export default SignupForm;
