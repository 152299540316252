import { Icons } from '@/components/icons';
import RefundOverview from '@/components/manage/refund/RefundOverview';
import { RefundSelection } from '@/components/manage/refund/RefundSelection';
import { Button } from '@/components/primitives/Button';
import { Checkbox } from '@/components/primitives/Checkbox';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/primitives/Dialog';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@/components/primitives/Sheet';
import { Typography } from '@/components/primitives/Typography';
import { refundLoadingSelector } from '@/features/loading/loadingSelectors';
import { TransText } from '@/i18n/trans/text';
import { useDispatch, useSelector } from '@/store/utils';
import { breakpoints } from '@/utils/breakpoints';
import { cn } from '@/utils/cn';
import { type FC, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  manageFlowBookingSelector,
  refundSelectedAdmissionsSelector,
} from '@/features/manage/manageSelectors';
import {
  confirmRefundOffer,
  deleteRefundOffer,
  getManageFlowBookingById,
  initiateRefund,
  resetRefundSelection,
} from '@/features/manage/manageActions';
import { getConsents } from '@/features/purchase/purchaseService';
import sanitizeMarkup from '@/utils/sanitizeMarkup';
import { toast } from 'react-toastify';

interface RefundModalProps {
  isOpen: boolean;
  onClose: () => void;
}

enum RefundStep {
  selection,
  confirmation,
}

interface RequiredConsent {
  id: string;
  text: string;
  url?: string;
}

const RefundModal: FC<RefundModalProps> = ({ isOpen, onClose }) => {
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const selectedAdmissions = useSelector(refundSelectedAdmissionsSelector);
  const isLoading = useSelector(refundLoadingSelector);
  const [currentStep, setCurrentStep] = useState<RefundStep>(
    RefundStep.selection
  );
  const currentBooking = useSelector(manageFlowBookingSelector);
  const dispatch = useDispatch();
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  const [requiredConsents, setRequiredConsents] = useState<RequiredConsent[]>(
    []
  );

  const handleClose = useCallback(() => {
    onClose();
    dispatch(resetRefundSelection());
    setCurrentStep(RefundStep.selection);
  }, [dispatch, onClose]);

  const handleOpenChange = useCallback(
    async (open: boolean) => {
      if (!open && currentStep === RefundStep.confirmation) {
        await dispatch(deleteRefundOffer()).unwrap();
      }
      handleClose();
    },
    [currentStep, dispatch, handleClose]
  );

  const handleBackToSelection = useCallback(async () => {
    await dispatch(deleteRefundOffer()).unwrap();
    setCurrentStep(RefundStep.selection);
  }, [dispatch]);

  const handleInitiateRefund = useCallback(async () => {
    await dispatch(initiateRefund()).unwrap();
    setCurrentStep(RefundStep.confirmation);
  }, [dispatch]);

  const handleConfirmRefund = useCallback(async () => {
    await dispatch(confirmRefundOffer()).unwrap();
    await dispatch(getManageFlowBookingById(currentBooking?.id)).unwrap();

    toast.success(<TransText i18nKey="refundInitiated" />);
    handleClose();
  }, [currentBooking, dispatch, handleClose]);

  useEffect(() => {
    const fetchConsents = async () => {
      try {
        const response = await getConsents();
        const ticketCancellationConsents =
          response.items
            ?.filter((consent) =>
              consent.requiredForCustomerActions?.some(
                (action) => action.id === 'CUSTOMER_ACTION.TICKET_CANCELLATION'
              )
            )
            .map((consent) => ({
              id: String(consent.id),
              text: consent.description || consent.name || '',
            })) || [];

        setRequiredConsents(ticketCancellationConsents);
      } catch (error) {
        setRequiredConsents([]);
      }
    };

    if (isOpen) {
      fetchConsents();
    }
  }, [isOpen]);

  const renderFooter = (isLaptopOrBigger: boolean) => {
    return currentStep === RefundStep.selection ? (
      <div
        className={cn('ml-auto mr-0 flex gap-2', {
          'ml-auto flex w-full gap-2': !isLaptopOrBigger,
        })}
      >
        <Button
          variant="tertiary"
          data-testid="cancel-refund-btn"
          onClick={handleClose}
          className="w-full laptop:w-fit"
        >
          <TransText i18nKey="cancel" />
        </Button>
        <Button
          data-testid="continue-refund-btn"
          onClick={handleInitiateRefund}
          disabled={!Object.keys(selectedAdmissions).length || isLoading}
          className="w-full laptop:w-fit"
        >
          {isLoading ? (
            <Icons.loader className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <Icons.ticketReturn height={16} width={16} />
          )}
          <TransText i18nKey="continue" />
        </Button>
      </div>
    ) : (
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-[150px]">
          {requiredConsents.map((consent) => (
            <Checkbox
              key={consent.id}
              className="h-6 w-6"
              id={`consent-${consent.id}`}
              checked={areTermsAccepted}
              onCheckedChange={(checked) =>
                setAreTermsAccepted(checked === true)
              }
            >
              <Typography
                variant="body1"
                className="[&_a]:text-primary [&_a]:underline hover:[&_a]:text-primary-dark"
                dangerouslySetInnerHTML={{
                  __html: sanitizeMarkup(consent.text),
                }}
              />
            </Checkbox>
          ))}
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="tertiary"
            data-testid="back-btn"
            onClick={handleBackToSelection}
            className="w-full laptop:w-fit"
          >
            <Icons.arrowLeft height={16} width={16} />
            <TransText i18nKey="back" />
          </Button>
          <Button
            data-testid="confirm-refund-btn"
            onClick={handleConfirmRefund}
            disabled={
              isLoading || (requiredConsents.length > 0 && !areTermsAccepted)
            }
            className="w-full laptop:w-fit"
          >
            {isLoading ? (
              <Icons.loader className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Icons.ticketReturn height={16} width={16} />
            )}
            <TransText i18nKey="confirm" />
          </Button>
        </div>
      </div>
    );
  };

  const renderModal = () => {
    const title =
      currentStep === RefundStep.selection ? (
        <TransText i18nKey="chooseTripsToRefund" />
      ) : (
        <TransText
          i18nKey="refundTrips"
          values={{ count: Object.keys(selectedAdmissions).length }}
        />
      );

    return isLaptopOrBigger ? (
      <Dialog open={isOpen} onOpenChange={handleOpenChange}>
        <DialogContent className="w-[480px]">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <DialogDescription className="sr-only">
            <TransText i18nKey="refundModalDescription" />
          </DialogDescription>
          <DialogBody>
            <div className="flex-grow overflow-auto px-6 py-4">
              {currentStep === RefundStep.selection ? (
                <RefundSelection />
              ) : (
                <RefundOverview />
              )}
            </div>
          </DialogBody>
          <DialogFooter>{renderFooter(isLaptopOrBigger)}</DialogFooter>
        </DialogContent>
      </Dialog>
    ) : (
      <Sheet open={isOpen} onOpenChange={handleOpenChange}>
        <SheetContent
          side="bottom"
          className="flex h-full flex-col border-none"
        >
          <SheetHeader className="h-14 shadow-md">
            <div className="ml-4 flex h-full items-center gap-1">
              <Button
                data-testid="refund-sheet-back-btn"
                className="px-0 text-dark"
                variant="ghost"
                onClick={handleClose}
                aria-label="Close"
              >
                <Icons.arrowLeft height={24} width={24} />
              </Button>
              <Typography variant="subtitle">{title}</Typography>
            </div>
          </SheetHeader>
          <SheetTitle className="sr-only">{title}</SheetTitle>
          <SheetDescription className="sr-only">
            <TransText i18nKey="refundModalDescription" />
          </SheetDescription>
          <div className="flex-grow overflow-auto px-6">
            {currentStep === RefundStep.selection ? (
              <RefundSelection />
            ) : (
              <RefundOverview />
            )}
          </div>
          <SheetFooter className="border-t border-neutral-light p-4">
            {renderFooter(!isLaptopOrBigger)}
          </SheetFooter>
        </SheetContent>
      </Sheet>
    );
  };

  return renderModal();
};

export default RefundModal;
