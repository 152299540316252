import { createAsyncThunk } from '@/store/utils';
import type { BackgroundTheme, BrandColors, BrandOption } from '@/types/color';
import {
  ARENAWAYS_BRAND_COLORS,
  DEFAULT_BACKGROUND_THEME,
  DEFAULT_RADIUS,
  TURNIT_BRAND_COLORS,
} from '@/utils/consts';
import type { PoSConfigurationDTO } from '@/types/dto';
import getApiInstance from '@/api';
import {
  getBackgroundColor,
  getCalculatedStyleProperties,
  setGlobalStyleProperties,
} from '@/utils/color';
import currencySymbolMap from '@/utils/currencySymbolMap';
import type { CurrencyConfiguration } from '@/types/configuration';

export const getConfiguration = createAsyncThunk<void, void>(
  'configuration/getConfiguration',
  (_, { getState, dispatch }) => {
    const {
      configuration: { currency, brand },
    } = getState();

    if (!currency.name) {
      dispatch(getCurrencyConfiguration());
    }

    if (!brand.brandColors || !brand.backgroundTheme) {
      dispatch(getBrandConfiguration());
    }
  }
);

export const getBrandConfiguration = createAsyncThunk<
  {
    brandColors: BrandColors;
    backgroundTheme: BackgroundTheme;
  },
  BrandOption['value'] | undefined
>('configuration/getBrandConfiguration', async (brandType = 'server') => {
  let newBrandColors: BrandColors;

  switch (brandType) {
    case 'turnit':
      newBrandColors = TURNIT_BRAND_COLORS;
      break;
    case 'arenaways':
      newBrandColors = ARENAWAYS_BRAND_COLORS;
      break;
    case 'server':
      try {
        //const api = (await getApiInstance()).agentApi;
        // TODO: Implement actual API call
        // const response = await api.getConfiguration();
        // newBrandColors = response.brandColors;
        newBrandColors = TURNIT_BRAND_COLORS; // Fallback to Turnit colors for now
      } catch (error) {
        console.error('Failed to fetch brand configuration:', error);
        newBrandColors = TURNIT_BRAND_COLORS;
      }
      break;
    default:
      newBrandColors = TURNIT_BRAND_COLORS;
  }

  const newBackgroundTheme = DEFAULT_BACKGROUND_THEME;
  const calculatedStyleProperties =
    getCalculatedStyleProperties(newBrandColors);
  const newBackgroundColor = getBackgroundColor(
    newBackgroundTheme,
    calculatedStyleProperties
  );

  setGlobalStyleProperties(
    {
      ...calculatedStyleProperties,
      background: newBackgroundColor,
      radius: DEFAULT_RADIUS,
    },
    newBackgroundTheme
  );

  return {
    brandColors: newBrandColors,
    backgroundTheme: DEFAULT_BACKGROUND_THEME,
  };
});

export const getCurrencyConfiguration = createAsyncThunk<
  CurrencyConfiguration,
  void
>('configuration/getCurrencyConfiguration', async () => {
  const api = (await getApiInstance()).agentApi;
  const response: PoSConfigurationDTO = (
    await api.PointOfSaleConfiguration_GetSalesPointConfiguration()
  ).data;

  if (!response.currencies?.[0]) {
    return { name: null, symbol: null };
  }

  return {
    name: response.currencies[0],
    symbol: currencySymbolMap[response.currencies[0]],
  };
});
