import { useCallback, type FC } from 'react';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Button } from '@/components/primitives/Button';
import {
  cancelPurchaseFlowBooking,
  setActiveStep,
} from '@/features/purchase/purchaseActions';
import { STEP } from '@/utils/consts';
import { TransAlert } from '@/i18n/trans/alert';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from '@/store/utils';
import { purchaseFlowBookingSelector } from '@/features/purchase/purchaseSelectors';
import { persistor } from '@/store';
import ConfirmModal from '@/components/ConfirmModal';

const CancelPurchaseButton: FC = () => {
  const dispatch = useDispatch();
  const booking = useSelector(purchaseFlowBookingSelector);

  const handleCancelPurchase = useCallback(async () => {
    if (!booking?.id) {
      return;
    }

    await dispatch(cancelPurchaseFlowBooking(booking.id))
      .unwrap()
      .then(() => {
        persistor.purge();
        dispatch(setActiveStep(STEP.PreSearchSubmit));
      })
      .catch(() => {
        toast.error(<TransAlert i18nKey="purchaseCancellationFailed" />);
      });
  }, [booking?.id, dispatch]);

  return (
    <ConfirmModal
      title={
        <Typography variant="subtitle">
          <TransText i18nKey="cancelPurchase" />
        </Typography>
      }
      description={
        <Typography variant="paragraph" className="text-dark">
          <TransText i18nKey="areYouSureYouWantToCancelThePurchase" />
        </Typography>
      }
      cancelLabel="dismiss"
      confirmLabel="confirm"
      onConfirm={handleCancelPurchase}
    >
      <Button
        size="large"
        fullWidth
        data-testid="cancel-purchase-button"
        className="rounded-lg laptop:h-11 laptop:w-auto"
        variant="tertiary"
      >
        <Icons.cancel />
        <Typography variant="button">
          <TransText i18nKey="cancel" />
        </Typography>
      </Button>
    </ConfirmModal>
  );
};

export default CancelPurchaseButton;
