import { cn } from '@/utils/cn';
import React from 'react';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  useFormField,
} from '@/components/primitives/Form';
import { Input } from '@/components/primitives/Input';
import type {
  Control,
  ControllerRenderProps,
  FieldValues,
  Path,
} from 'react-hook-form';

interface InputFieldContentProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> extends Omit<InputFieldProps<TFieldValues, TName>, 'control' | 'name'> {
  field: ControllerRenderProps<TFieldValues, TName>;
}

const InputFieldContent = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
  field,
  label,
  hideLabel,
  endAdornment,
  className,
  required,
  ...props
}: InputFieldContentProps<TFieldValues, TName>) => {
  const { error } = useFormField();

  return (
    <FormItem>
      <FormControl
        label={<FormLabel required={required}>{label}</FormLabel>}
        hideLabel={hideLabel}
      >
        <Input
          {...field}
          {...props}
          className={cn(className, {
            ['border-error-text hover:border-error-text focus-visible:border-error-text focus-visible:ring-error-text']:
              error,
          })}
          aria-invalid={!!error}
          {...(endAdornment && { endAdornment })}
        />
      </FormControl>
    </FormItem>
  );
};

export interface InputFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name'> {
  label: React.ReactNode;
  name: TName;
  control: Control<TFieldValues>;
  hideLabel?: boolean;
  endAdornment?: React.ReactNode;
}

export const InputField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
  control,
  name,
  ...props
}: InputFieldProps<TFieldValues, TName>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => <InputFieldContent field={field} {...props} />}
    />
  );
};

InputField.displayName = 'InputField';
